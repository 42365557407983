.grant-section {
    padding: 5rem 0;
    background-color: rgba(255, 255, 255, 0.50);
    border-radius: 10px;
    position: relative;
}

.grant-container-header h1 {
    color: black;
    font-size: 2.5rem;
    max-width: 300px;
    margin-bottom: 1.5rem;
}

.grant-container-body {
    display: flex;
    align-items: center;
}

.grant-container-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}




.grant-tab {
    display: flex;
    column-gap: 15px;
    align-items: center;
    cursor: pointer;
}

.grant-tab img {
    width: 60px;
    height: 60px;
    min-width: 60px;
}

.grant-tab h1 {
    color: black;
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0;
}

.grant-tab-content {
    padding: 0 1rem 1rem 1rem;
    border-left: 1px solid #007bff;
    border-bottom: 1px solid #007bff;
    border-bottom-left-radius: 10px;
    margin-left: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

}

.grant-tab-content::before {
    content: '';
    width: 26px;
    height: 26px;
    background-color: #007BFF;
    border-radius: 5px;
    position: absolute;
    right: 0;
    bottom: -13px;
}

.grant-tab-content p {
    font-size: 1rem;
    color: black;
    margin: 0;
    padding-left: 30px;
}

.grant-tab-content a {
    text-decoration: none;
    padding: 0.8rem 2rem;
    background-color: #007BFF;
    color: white;
    border-radius: 10px;
    display: flex;
    column-gap: 5px;
    align-items: center;
    width: fit-content;
    margin-left: 30px;
}

.grant-container-right {
    width: 500px;
    min-width: 500px;
    display: flex;
    justify-content: center;
}

.grant-container-right img.grant-img {
    width: 300px;
    object-fit: cover;
    height: 450px;
    border-radius: 10px;
    position: relative;
    margin-top: 50px;

}

.grant-container-right img.grant-rectangle {
    width: 200px;
    height: 450px;
    margin-left: -50px;
}

@media screen and (max-width:991px) {
    .grant-container-body {
        flex-direction: column;
        row-gap: 2rem;
    }

    .grant-container-left {
        order: 2;
    }

    .grant-container-right {
        width: 100%;
        min-width: 100%;
        padding: 0 2rem;
    }

    .grant-container-right img.grant-img {
        height: 350px;
        width: 100%;
    }

    .grant-container-right img.grant-rectangle {
        height: 350px;
        width: 100%;
    }
}

@media screen and (max-width:576px) {
    .grant-section {
        padding: 2rem 0;
    }

    .grant-container-header h1 {
        font-size: 1.7rem;
    }

    .grant-container-right img.grant-img {
        height: 300px;

    }

    .grant-container-right img.grant-rectangle {
        height: 300px;

    }

    .grant-tab img {
        width: 40px;
        min-width: 40px;
        height: 40px;
    }

    .grant-tab h1 {
        font-size: 1.2rem;
    }

    .grant-tab-content {
        margin-left: 20px;
    }

    .grant-tab-content p {
        padding-left: 20px;
    }

    .grant-tab-content a {
        margin-left: 20px;
    }
}