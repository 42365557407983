.about-section {
    padding: 2rem 0;
    background: url(../../../assets/images/grid-bg.png);
    background-position: center;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
}

.about-container {
    display: flex;
    align-items: center;
    column-gap: 2rem;
    position: relative;
    background-color: #0f141c9f;
    border-radius: 15px;
    padding: 1rem 0;
}

.about-container-left {
    width: 50%;
}

.about-container-right {
    width: 50%;
    min-width: 600px;
}

.about-container-left h1 {
    display: flex;
    flex-direction: column;
    font-size: 2.5rem;
    font-weight: 600;
    color: white;
    margin-bottom: 2rem;
}

.about-container-left h1 span {
    color: #FFD200;
}

.about-container-left h1 span img {
    width: 80px;
    min-width: 80px;
}

.about-container-left p {
    color: white;
    font-size: 1rem;
}

.about-container-left p span:nth-of-type(1) {
    color: #FF6161;
}

.about-container-left p span:nth-of-type(2) {
    color: #7EFFA2;
}

.about-container-right {
    display: flex;
}

.about-container-right .about-right-wrapper {
    position: relative;
    margin-top: 20px;
}

.year-counter {
    background: #FFD200;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    left: 50px;
    bottom: 50px;
    z-index: 5;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.2rem;
}

.about-container-right .about-right-wrapper img {
    width: 450px;
    height: 450px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: -50px;
}

.about-container-right img.rectangle-back {
    width: 200px;
    height: 430px;
}

.about-container-two .about-container-right .about-right-wrapper img {
    margin-left: -50px;
    margin-right: unset;
}

.about-container-two .about-container-left {
    order: 2;
}

.about-container-two .about-container-right .about-right-wrapper {
    order: 2;
}

.about-container-two .about-container-right img.rectangle-back {
    transform: rotate(180deg);
}

.about-container-two .about-container-left a {
    text-decoration: none;
    padding: 0.8rem 2rem;
    background-color: #007BFF;
    color: white;
    border-radius: 10px;
    display: flex;
    column-gap: 5px;
    align-items: center;
    width: fit-content;
}

@media screen and (max-width:1199px) {
    .about-container {
        flex-direction: column;
        row-gap: 2rem;
    }

    .about-container-left {
        order: 2;
        width: 100%;
    }
}

@media screen and (max-width:767px) {
    .about-container-right {
        width: 100%;
        min-width: 100%;
        position: relative;
    }

    .about-container-right {
        width: calc(100% - 80px);
    }

    .about-container-right .about-right-wrapper img {
        width: 90%;
        position: relative;
        z-index: 2;
    }

    .about-container-right img.rectangle-back {
        position: absolute;
        right: 0;
        width: 200px;
        z-index: 1;
    }

    .about-container-two .about-container-right img.rectangle-back {
        position: absolute;
        left: 0;
        width: 200px;
        z-index: 1;
    }

    .about-container-two .about-container-right .about-right-wrapper {
        text-align: right;
    }
}

@media screen and (max-width:576px) {
    .about-container-right .about-right-wrapper img {
        width: 100%;
        height: 100%;
    }

    .about-container-right .about-right-wrapper {
        height: 300px;
        width: 90%;
    }

    .about-container-two .about-container-right {
        justify-content: flex-end;
    }



    .about-container-right img.rectangle-back {
        height: 200px;
        width: 100px;
    }

    .about-container-left h1 {
        font-size: 1.7rem;
    }

    .about-container-left h1 span img {
        width: 40px;
        min-width: 40px;
    }

    .about-container-two .about-container-left a {
        padding: 0.8rem 0.5rem;
        font-size: 0.9rem;
    }
}