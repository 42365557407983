.risk-section-main {
    padding: 90px;
    position: relative;
}

.risk-section-container {
    background-color: #f0f1f59c;
    border-radius: 15px;

    padding-top: 0px;
    justify-content: center;
}

.risk-section-heading {
    background-color: white;
    top: 0;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0px 0px 30px 30px;
    width: fit-content;
    margin: 0 auto;
    text-align: center;
}

.risk-section-heading h1 {
    font-size: 2.5rem;
    font-weight: 800 !important;
    text-align: center;
    margin-bottom: 0px;
}

.risk-section-body {
    width: 100%;
    display: flex;
    padding-bottom: 1rem;
    margin-top: 10px;
}

.risk-secion-content {
    width: 50%;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.risk-secion-content p {
    font-size: 1.4rem;
    margin-bottom: 0px;
    font-weight: 500;
}

.risk-secion-content span {
    color: #007BFF;
    font-size: 1.4rem;
    margin-bottom: 0px;
    font-weight: 500;

}

.get-start-button {
    background-color: #007BFF;
    color: white;
    font-size: 1rem;
    padding: 0.7rem 2rem;
    border: none;
    box-shadow: none;
    border-radius: 10px;
    width: fit-content;
    margin: 1.5rem 0rem 1rem 0rem;
}



.risk-secion-image {
    width: 50%;
}

.risk-secion-image img {
    width: 100%;
    height: auto;
}

@media screen and (max-width: 991px) {
    .risk-section-body {
        flex-direction: column-reverse;
    }

    .risk-section-heading h1 {
        font-size: 1.7rem;
    }

    .risk-section-heading {
        width: 65%;
    }

    .risk-secion-content span {
        margin-top: unset;
    }

    .get-start-button {
        margin: 1.5rem 0rem 1rem 0rem;
    }

    .risk-secion-content {
        width: 100%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
    }

    .risk-secion-image {
        width: 100%;
    }
}

@media screen and (max-width: 576px) {

    .risk-secion-content span {
        font-size: 1rem;
    }

    .risk-secion-content p {
        font-size: 1rem;
    }

    .get-start-button {
        width: 100%;
    }

    .risk-section-heading {
        padding: 0.5rem 0.5rem;

    }

    .risk-section-main {
        padding: 1rem;
        margin: 3rem 0 2rem 0;
    }

    .risk-section-container {
        padding: unset;
    }

}