.impact-section {
  padding: 2rem 0;
}

.impact-section-container {
  background-color: #f0f1f5b6;
  border-radius: 10px;
  position: relative;
}

.impact-section-container::before {
  content: url(../../../assets/images/impact-icon-background.png);
  width: 80px;
  position: absolute;
  top: 0;
  right: 50px;
}

.impact-section-header h1 {
  color: black;
  font-size: 2.5rem;
  margin: 0;
  font-weight: 600;
  background-color: white;
  padding: 1rem;
  border-bottom-right-radius: 10px;
  width: fit-content;
  position: relative;
}

.impact-card {
  background-color: white;
  border-radius: 10px;
  border-right: 2px solid #007bff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  height: 100%;
}

.impact-card img {
  width: fit-content;
  max-width: 150px;
}

.impact-card h1 {
  font-size: 1rem;
  margin: 0;
  color: black;
  font-weight: 600;
  word-break: break-all;
}

.impact-card p {
  color: #828384;
  font-size: 1rem;
  margin: 0;
}

@media screen and (max-width: 576px) {
  .impact-section-header h1 {
    font-size: 1.7rem;
    border-bottom-left-radius: 10px;
  }
}
