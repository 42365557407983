.feedback-section {
    padding: 5rem 0;

}

.feedback-container {
    border-radius: 10px;
    display: flex;
    height: 80vh;
    position: relative;
}

.feedback-container-left {
    width: 450px;
    min-width: 450px;
    height: 100%;
    background: linear-gradient(to right, #f0f1f5de, #e5e6f3c7);
    border-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.feedback-container-right {
    width: 100%;
    display: flex;
    padding: 0 1rem;
    background: linear-gradient(to right, #e5e6f3c5, #cfcfefb7);
    border-radius: 10px;
    border-bottom-left-radius: 0;
}

.feedback-header h1 {
    font-size: 2.5rem;
    font-weight: 600;
    color: black;
    padding: 1rem;
    background-color: white;
    width: 100%;
    border-bottom-right-radius: 10px;
}

.feedback-left-body {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 2rem 1rem;
    justify-content: center;
    height: calc(80vh - 100px);
}

.feedback-left-body h1 {
    color: black;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
}

.feedback-left-body p {
    margin: 0;
    color: #828384;
    font-size: 1rem;
}

.feedback-left-body a {
    text-decoration: none;
    padding: 0.8rem 2rem;
    background-color: #007BFF;
    color: white;
    border-radius: 10px;
    display: flex;
    column-gap: 5px;
    align-items: center;
    width: fit-content;
}

.feedback-wrapper {
    width: 50%;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    max-height: 100%;
    overflow-y: auto;
}

.feedback-wrapper::-webkit-scrollbar {
    width: 0px;
}

.feedback-wrapper::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.feedback-card {
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
}

.feedback-card p {
    font-size: 0.9rem;
    color: black;
}

.feedback-card-footer {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.feedback-card-footer h1 {
    margin: 0;
    font-size: 0.9rem;
    color: black;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    row-gap: 0.3rem;
}

.feedback-card-footer h1 span {
    color: #828384;
    font-weight: 500;
}

.feedback-card-footer img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    min-width: 40px;
}

.feedback-card-footer .reviewer-name {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    min-width: 40px;
    background-color: #007BFF;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
}

@media screen and (max-width:991px) {
    .feedback-container-right .feedback-wrapper {
        width: 100%;
    }

    .feedback-container-right {
        max-height: 100%;
        overflow: auto;
        flex-direction: column;
        row-gap: 1rem;
        padding: 1rem 0;
    }

    .feedback-wrapper {
        overflow: unset;
        max-height: unset;
        height: unset;
    }

}

@media screen and (max-width:767px) {
    .feedback-container {
        flex-direction: column;
        background: linear-gradient(to right, #e5e6f3, #cfcfef);
        border-radius: 10px;
        height: 100%;
    }

    .feedback-header h1 {
        width: fit-content;
    }

    .feedback-left-body {
        height: 100%;
    }

    .feedback-container-left {
        width: 100%;
        min-width: 100%;
        background: transparent;
    }

    .feedback-container-right {
        background: transparent;
        max-height: 500px;
    }

}

@media screen and (max-width:576px) {
    .feedback-section {
        padding: 2rem 0;
    }

    .feedback-header h1 {
        font-size: 1.7rem;
        width: 100%;
        border-bottom-left-radius: 10px;
    }

    .feedback-left-body h1 {
        font-size: 1.2rem;
    }
}