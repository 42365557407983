.team-section {
    padding: 2rem 0;
}

.team-container {
    background-color: rgba(255, 255, 255, 0.50);
    border-radius: 10px;
    position: relative;
}

.team-header h1 {
    display: flex;
    flex-direction: column;
    color: black;
    font-size: 2.5rem;
    margin: 0;
    font-weight: 600;
    width: 100%;
    max-width: 55%;
    row-gap: 1rem;
}

.team-header h1 span {
    font-size: 1rem;
    font-weight: 500;
}

.team-header h1 img {
    width: 100%;
}

.team-card-wrapper {
    padding: 1rem;
}

.team-card {
    height: 400px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.team-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.team-profile {
    background-color: white;
    padding: 1rem;
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 50%;
    width: fit-content;
    border-top-right-radius: 10px;
}

.team-profile h1 {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0;
    color: black;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.team-profile h1 span {
    font-weight: 500;
}

.slick-next:before {
    content: url('../../../assets/images/slide-right.png');
    width: 30px !important;
    height: 30px !important;
}

.slick-prev:before {
    content: url('../../../assets/images/slide-left.png');
    width: 30px !important;
    height: 30px !important;
}

.slick-arrow {
    position: absolute;
    top: -100px;
    right: 10px;
}

.slick-prev {
    left: unset;
    right: 50px;
}

.team-card .team-card-back .team-card-back-content {
    font-size: 0.9rem;
    color: white;
    padding: 1rem;
}

.team-card-content {
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    transition: transform 1s;
    transform-style: preserve-3d;
}

.team-card:hover .team-card-content {
    transform: rotateY(180deg);
    transition: transform 0.5s;
}

.team-card-front,
.team-card-back {
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
}

.team-card-back {
    background: #0F141C;
    color: white;
    transform: rotateY(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width:767px) {
    .team-header h1 {
        max-width: 100%;
    }

}

@media screen and (max-width:576px) {
    .team-header h1 {
        font-size: 1.7rem;
    }

    .team-card .team-card-back .team-card-back-content {
        font-size: 0.8rem;
    }
}