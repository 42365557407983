.business-section {
    background: url(../../../assets/images/grid-bg.png);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    padding: 5rem 0;
}

.business-container {
    position: relative;
}

.common-card {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    background: rgba(15, 20, 28, 0.50);
    border-radius: 15px;
    height: 100%;
}

.common-heading {
    background: rgba(15, 20, 28, 0.50);
    border-radius: 15px;
    padding: 0.5rem 0;
}

.common-heading h1 {
    font-size: 2.5rem;
    font-weight: 600;
    color: white;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin: 0;
    text-align: center;

}

.common-heading h1 span {
    color: #828384;
    font-size: 1rem;
    font-weight: 500;
    max-width: 800px;
    margin: 0 auto;
}

.common-card-header {
    height: 450px;
    position: relative;
}

.common-card-header-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
}

.common-card-header-text h1 {
    color: #FFD200;
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0;
    background-color: #0F141C;
    padding: 1rem 0.5rem;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    width: 100%;
}

.common-card-header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 15px;
}

.common-card-body {
    padding: 0.5rem;
}

.common-card-body p {
    color: white;
    font-size: 1rem;
    margin: 0;
}

@media screen and (max-width:576px) {
    .common-heading h1 {
        font-size: 1.7rem;
    }

    .business-section {
        padding: 2rem 0;
    }

    .common-card-header {
        height: 350px;
    }

    .common-card-header-text h1 {
        font-size: 1rem;
        padding: 0.5rem;
    }
}