.metering-section {
    padding: 2rem 0 5rem 0;
}

.metering-container {
    position: relative;
    background-color: rgba(255, 255, 255, 0.747);
    border-radius: 15px;
}

.metering-container-header h1 {
    color: black;
    display: flex;
    flex-direction: column;
    margin: 0;
    text-align: center;
    position: relative;
    row-gap: 1rem;
    padding-bottom: 3rem;
    width: fit-content;
    margin: 0 auto;
}

.metering-container-header h1 span:first-of-type {
    font-size: 2.5rem;
    font-weight: 600;
}

.metering-container-header h1 span:first-of-type span {
    color: #828384;
}

.metering-container-header h1 span {
    font-size: 1rem;
}

.metering-container-header h1 img {
    width: 100%;
    position: absolute;
    bottom: 0;
}



.metering-card {
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
    height: 100%;
}

.metering-card img {
    width: 80px;
}

.metering-card h1 {
    color: black;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
}

.metering-card p {
    color: #828384;
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
}

.metering-image {
    height: 100%;
    display: flex;
    align-items: center;
}

.metering-image img {
    width: 100%;
    border-radius: 10px;
    max-width: 500px;
    margin: 0 auto;
}

@media screen and (max-width:576px) {
    .metering-section {
        padding: 2rem 0;
    }

    .metering-container-header h1 span:first-of-type {
        font-size: 1.7rem;
    }
}