@font-face {
  font-family: "Gilroy";
  src: url("../src/assets/gilroy/Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../src/assets/gilroy/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../src/assets/gilroy/Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Gilroy", sans-serif;
}

body,
html {
  overflow-x: hidden;
}

.page-loader {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 1000;
  top: 0;
  bottom: 0;
  /* background-color: black; */
}

.page-loader video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none !important;
}

.navbar-wrapper {
  display: flex;
  background-color: #0f141c;
  padding: 0.5rem 30px;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  position: relative;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 50;
}

.navbar-logo a img {
  width: 160px;
  min-width: 160px;
}

.navbar-links {
  display: flex;
  align-items: center;
}

.navbar-links ul {
  list-style: none;
}

.navbar-links ul {
  margin: 0;
  display: flex;
  column-gap: 1.5rem;
  align-items: center;
}

.navbar-links ul li a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  position: relative;
}

.navbar-links ul li a img {
  width: 10px;
}

.navbar-links ul li a.active::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #007bff;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.navbar-links ul li.get-started {
  background-color: #007bff;
  padding: 0.8rem 1.5rem;
  border-radius: 10px;
  text-align: center;
}

.top-wrapper {
  margin-top: 80px;
}

.menu-toggle-wrapper img {
  display: none;
  max-width: 24px;
  min-width: 16px;
  width: 100%;
}

.services-dropdown-wrapper {
  position: relative;
}

.services-submenu {
  position: absolute;
  width: fit-content;
  padding: 1rem;
  top: 50px;
  flex-direction: column;
  row-gap: 0.8rem;
  background-color: #0f141c;
  align-items: flex-start !important;
  display: none;
}

.services-submenu.close {
  display: none !important;
}

.services-submenu li a {
  white-space: nowrap;
}

@media screen and (max-width: 1300px) {
  .navbar-links ul {
    column-gap: 1rem;
  }
}

@media screen and (max-width: 1199px) {
  .services-submenu {
    position: unset;
    padding: 0 !important;
    padding-left: 1rem !important;
  }

  .menu-toggle-wrapper img {
    display: block;
  }

  .navbar-links {
    position: fixed;
    left: 0;
    top: 80px;
    bottom: 0;
    height: 100vh;
    background-color: #0f141c;
    min-width: 300px;
    width: 300px;
    align-items: flex-start;
    left: -300px;
    transition: all 1s;
  }

  .open {
    left: 0 !important;
  }

  .navbar-links ul {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding: 1.5rem;
  }

  .navbar-links ul li {
    width: 100%;
  }

  .navbar-links ul li a {
    display: flex;
    width: 100%;
    padding: 0.5rem;
    align-items: center;
    column-gap: 0.5rem;
  }

  .navbar-links ul li.get-started {
    margin-top: 1rem;
  }

  .navbar-links ul li.get-started a {
    padding: 0;
  }

  .navbar-links ul li a.active {
    background-color: #2e3a4f;
  }

  .navbar-links ul li a.active::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #007bff;
    position: absolute;
    bottom: unset;
    left: -12px;
    transform: translate(0%, -50%);
    top: 50%;
  }
}

@media screen and (max-width: 767px) {
  .navbar-logo a img {
    width: 130px;
    min-width: 130px;
  }
}

@media screen and (max-width: 576px) {
  .navbar-links {
    width: 100%;
    left: -100%;
  }
}

/* footer */
.footer-section {
  position: relative;
  background-color: rgba(255, 255, 255, 0.8);
}

.footer-container {
  display: flex;
  column-gap: 2rem;
  padding: 2.5rem 0rem;
}

.footer-logo-section {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}

.footer-logo-section img {
  width: 100%;
  max-width: 180px;
  min-width: 150;
}

.footer-logo-section h1 {
  color: #828384;
  font-size: 1.7rem;
  font-weight: 600;
  margin: 0;
}

.footer-logo-section h1 span {
  color: #090a0a;
}

.footer-logo-section p {
  color: #828384;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
}
.footer-logo-section a p img {
  width: 16px;
  min-width: 16px;
}
.footer-logo-section a p {
  display: flex;
  align-items: center;
  gap: 5px;
}
.footer-logo-section {
  width: 450px;
  min-width: 450px;
}

.footer-nav-section {
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
  width: 100%;
}

.footer-nav-section ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  padding: 0;
}

.footer-nav-section ul li a {
  color: #090a0a;
  font-size: 1.1rem;
  font-weight: 500;
}

.footer-nav-section ul li a:hover {
  color: #007bff;
  border-bottom: 1px solid #007bff;
}

.footer-nav-social-icon {
  display: none;
}

.footer-nav-social-icon a div {
  background-color: #f0f1f5;
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-nav-social-icon a div img {
  width: 25px;
  height: 25px;
}

.footer-section-condition {
  background-color: #f0f1f5;
  padding: 1rem 0;
}

.footer-section-condition-container {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
}

.footer-section-condition-container a {
  color: #090a0a;
  font-size: 1.1rem;
  font-weight: 500;
}

.footer-section-condition-container a:hover {
  border-bottom: 1px solid #090a0a;
}

.navbar-social-icon-wrapper {
  padding-top: 1rem;
  display: none;
}

.navbar-social-icon-wrapper ul {
  list-style: none;
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 0.5rem;
}

.navbar-social-icon-wrapper ul li {
  background-color: #495976;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-social-icon-wrapper ul li a {
  padding: 0;
  background: transparent !important;
  display: unset;
  width: fit-content;
}

.navbar-social-icon-wrapper ul li a div {
  background-color: #495976;
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-social-icon-wrapper ul li a img {
  width: 25px;
  height: 25px;
}

.navbar-social-icon-wrapper ul li a::before {
  display: none;
}

@media screen and (max-width: 991px) {
  .footer-logo-section {
    width: 100%;
    min-width: unset;
  }

  .footer-container {
    flex-direction: column;
    row-gap: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .footer-section-condition-container {
    flex-direction: column;
    row-gap: 1rem;
    justify-content: center;
    align-items: center;
  }

  .footer-section-condition-container a {
    text-align: center;
    font-size: 1rem;
  }
}

@media screen and (max-width: 576px) {
  .navbar-social-icon-wrapper {
    display: block;
  }

  .footer-nav-section {
    flex-direction: column;
    padding-top: 1.5rem;
  }

  .footer-logo-section h1 {
    font-size: 1.2rem;
  }

  .footer-nav-social-link {
    display: none !important;
  }

  .footer-logo-section svg path.path-color {
    fill: #090a0a;
  }

  .footer-nav-social-wrapper {
    flex-direction: row !important;
    justify-content: space-between;
    column-gap: 0.5rem;
  }

  .footer-nav-social-icon {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .animated-element {
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.5s, transform 0.5s;
  }
}

@media only screen and (max-width: 767px) {
  .animated-element {
    opacity: 1 !important;
    transform: none !important;
    transition: none !important;
  }
}

/* sun */
.sun-container {
  position: fixed;
  top: 80px;
  right: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.2;
}
