.solar-section {
  padding: 5rem 1rem;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(../../../assets/images/slide1.JPG);
  min-height: calc(100vh - 80px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.solar-container {
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.solar-container h1 {
  color: white;
  font-size: 4rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
}
.solar-container p {
  color: white;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  margin: 0;
}
.solar-container a {
  width: fit-content;
  padding: 0 2rem;
  margin: 0 auto;
  height: 50px;
  font-size: 1.2rem;
  font-weight: 500;
  background: #007bff;
  color: white;
  border-radius: 10px;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.solar-container a:hover {
  background-color: #007bffcc;
}
@media screen and (max-width: 576px) {
  .solar-container h1 {
    font-size: 2rem;
  }
  .solar-container p {
    font-size: 1.2rem;
  }
  .solar-container a {
    font-size: 1rem;
  }
}
