.card-common {
    background: linear-gradient(to right, #141A24, #2E3A4F);
    border-radius: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-common-counter-wrapper {
    padding: 1rem;
}

.pv-card-common .card-common-content .card-common-counter-wrapper .card-common-counter {
    height: 70px;
}

.pv-card-common .card-common-content .card-common-counter-wrapper .card-common-counter img {
    height: 70px;
}

.card-common-counter {
    position: relative;
    width: 70px;
    overflow: hidden;
}

.card-common-counter img {
    width: 70px;

}

.card-common-counter h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: white;
    font-weight: 600;
    margin: 0;
}

.pv-card-common .card-common-content .card-common-header {
    background: transparent;
    padding: 0 1rem;
    max-width: 100%;
}

.pv-card-common .card-common-content .card-common-body p {
    color: #828384;
}

.mission-card-common .card-common-content .card-common-header {
    background: transparent;
    padding: 0 1rem;
    max-width: 100%;
}

.mission-card-common .card-common-content .card-common-body p {
    color: #828384;
}

.card-common-header {
    background-color: #0F141C;
    max-width: 80%;
    width: fit-content;
    border-bottom-right-radius: 15px;
    padding: 0.5rem;
}



.card-common-header h1 {
    color: white;
    margin: 0;
    font-size: 1.3rem;
}

.card-common-body {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.card-common-body p {
    color: white;
    font-size: 1rem;
    margin: 0;
    font-weight: 500;
}

.card-common-body ul {
    border-top: 1px solid #586172B2;
    list-style: none;
    padding-top: 1rem;
    padding-left: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.card-common-body ul li {
    color: white;
    font-size: 1rem;
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
}

.card-common-body ul li svg {
    width: 15px;
    min-width: 15px;
}

.card-common-footer {
    padding: 1rem;
}

.card-common-footer a {
    text-decoration: none;
    padding: 0.8rem 2rem;
    background-color: #007BFF;
    color: white;
    border-radius: 10px;
    display: flex;
    column-gap: 5px;
    align-items: center;
    width: 100%;
    text-align: center;
    justify-content: center;
}

.card-common-body img {
    max-width: 80%;
    margin: 0 auto;
    width: fit-content;
}

@media screen and (max-width:576px) {

    .card-common-header h1 {
        font-size: 1.2rem;
    }
}