.blog-detail-section {
  padding: 5rem 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}

.blog-detail-container-content {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 1.5rem 1.5rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 850px;
  margin: 0 auto;
  backdrop-filter: blur(5px);
}

.blog-detail-container-content .blog-tags-wrapper {
  justify-content: center;
}

.blog-tags-wrapper {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.blog-tags-wrapper label {
  font-size: 1rem;
  color: white;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.blog-tags-wrapper label svg {
  min-width: 15px;
}
.blog-tags-wrapper label.active {
  border-color: #007bff;
  color: #007bff;
  font-weight: 600;
}

.blog-detail-container-content h1 {
  font-size: 2.5rem;
  margin: 0;
  color: #ffd200;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.blog-detail-container-content h1 span {
  color: white;
}

.blog-detail-container-content p {
  color: white;
  text-align: center;
  font-size: 1rem;
  margin: 0;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.blog-detail-container-content p svg {
  min-width: 15px;
}
@media screen and (max-width: 576px) {
  .blog-detail-section {
    padding: 2rem 0;
  }

  .blog-detail-container-content h1 {
    font-size: 1.7rem;
  }

  .blog-detail-container-content {
    padding: 0.5rem;
  }
}
