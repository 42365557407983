@media screen and (min-width: 768px) {
  .message-table-wrapper .table-header .table-cell,
  .message-table-wrapper .table-row .table-cell {
    flex: 1;
  }
  .message-table-wrapper .table-row .table-cell:nth-of-type(2) {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .message-table-wrapper .table-header .table-cell:last-of-type,
  .message-table-wrapper .table-row .table-cell:last-of-type {
    flex: 4;
  }
}
