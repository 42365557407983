.pv-section {
    padding: 5rem 0;
    background: url('../../../assets/images/grid-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}

.pv-container {
    position: relative;
}

.pv-container-header h1 {
    color: white;
    margin: 0;
    font-size: 2.5rem;
    position: relative;
    padding-bottom: 2.5rem;
    width: fit-content;
    margin: 0 auto;
    text-align: center;
}

.pv-container-header h1 img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.pv-card {
    background: linear-gradient(to right, #151b26, #2c384c);
    padding: 2rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    height: 100%;
}

.pv-card-counter {
    position: relative;
    width: 70px;
    height: 70px;
    overflow: hidden;
}

.pv-card-counter img {
    width: 70px;
    height: 70px;
}

.pv-card-counter h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: white;
    font-weight: 600;
    margin: 0;
}

.pv-card-body {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.pv-card-body h1 {
    color: white;
    font-size: 1.5rem;
    margin: 0;
}

.pv-card-body p {
    font-size: 1rem;
    color: #828384;
    margin: 0;
}

@media screen and (max-width:576px) {
    .pv-section {
        padding: 2rem 0;
    }

    .pv-container-header h1 {
        font-size: 1.7rem;
    }
}