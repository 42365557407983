@media screen and (min-width: 768px) {
  .question-table-wrapper .table-header .table-cell,
  .question-table-wrapper .table-row .table-cell {
    flex: 1;
  }
  .question-table-wrapper .table-row .table-cell:nth-of-type(2) {
    max-width: 100%;
  }
  .question-table-wrapper .table-header .table-cell:nth-of-type(2),
  .question-table-wrapper .table-row .table-cell:nth-of-type(2) {
    flex: 2;
  }
}
