.feature-section {
    padding: 5rem 0;
    background-color: rgba(255, 255, 255, 0.50);
    border-radius: 10px;
    position: relative;
}

.table-header {
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;

}

.table-header .table-cell.table-cell-main {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
    width: 300px;
    min-width: 300px;
    text-align: left;
}

.table-header .table-cell.table-cell-main span {
    font-size: 0.9rem;
    color: #828384;
    font-weight: 500;
}

.table-header .table-cell {
    font-size: 1.1rem;
    color: black;
    font-weight: 600;
    width: 25%;
    text-align: center;
    padding: 1rem 0;
}

.table-header .table-cell.table-cell-highlight {
    background-color: #f6f7f9e0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.table-row {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #E8EBF0;
    column-gap: 1rem;
}

.table-row .table-cell {
    width: 25%;
    text-align: center;
    color: black;
    font-size: 1rem;
    font-weight: 500;
    padding: 1rem 0;
}

.table-row .table-cell.table-cell-main {
    width: 300px;
    min-width: 300px;
    text-align: left;
}

.table-row .table-cell.table-cell-highlight {
    background-color: #F6F7F9;
}

.table-row:last-of-type .table-cell.table-cell-highlight {
    background-color: #f6f7f9e0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

@media screen and (max-width:991px) {
    .table-header .table-cell.table-cell-main {
        width: 200px;
        min-width: 200px;
    }

    .table-row .table-cell.table-cell-main {
        width: 200px;
        min-width: 200px;
    }
}

@media screen and (max-width:767px) {
    .table-row {
        border: none !important;
        background-color: rgba(255, 255, 255, 0.50);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        padding: 1rem;
        border-radius: 10px;
        margin-bottom: 1rem;
    }

    .table-header {
        display: none;
    }

    .table-cell:before {
        content: attr(data-heading);
        display: block;
        font-weight: 600;
        color: black;
        text-align: center;
    }

    .table-row {
        flex-direction: column;
    }

    .table-row .table-cell.table-cell-main {
        width: 100%;
        text-align: center;
    }

    .table-row .table-cell {
        display: flex;
        justify-content: space-between;
        width: 100%;
        column-gap: 2rem;

    }
}

@media screen and (max-width:576px) {
    .feature-section {
        padding: 2rem 0;
    }

    .table-row .table-cell {
        font-size: 0.9rem;
    }

}