.map-section {
  padding: 5rem 0rem 6rem 0rem;
  background-image: url("../../../assets/images/grid-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.map-container {
  position: relative;
}

.map-container-header h1 {
  color: white;
  font-size: 3rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0;
  row-gap: 1rem;
  background-color: #0f141ca1;
  padding: 1rem 0rem;
  border-radius: 10px;
}

.map-container-header h1 span {
  font-size: 1rem;
  font-weight: 500;
  max-width: 800px;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.6);
}

.map-container-header h1 span.map-subheading {
  color: #ffd200;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}

.map-container-header h1 span.map-subheading img {
  width: 30px;
}

.map-container-body {
  display: flex;
  column-gap: 2rem;
  align-items: center;
}

.map-content-left {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 280px;
  min-width: 250px;
}

.map-content-left .map-content-heading {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  color: white;
}

.map-content-left .impact-card {
  background-color: #495976;
  border-right: transparent;
}

.impact-card {
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
}

.impact-card h2 {
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.impact-card h2 div {
  width: 10px;
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
}

.map-content-left .impact-card h1 {
  font-size: 2rem;
  color: white;
  white-space: nowrap;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.impact-card h1 span {
  font-size: 0.9rem;
}

.map-content-right {
  width: 100%;
}

.map-box svg {
  width: 100%;
}

.map-box svg circle {
  cursor: pointer;
  transition: fill 0.3s ease-in-out;
}

.map-box svg circle:hover {
  fill: #ffd200;
  animation: bounce 0.5s ease infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-5px);
  }

  60% {
    transform: translateY(-5px);
  }
}

.map-card {
  background-color: white;
  min-width: 200px;
  max-width: 250px;
  width: 100%;
  border-radius: 10px;
  padding: 0.5rem;
}

.map-card h1 {
  color: #090a0a;
  font-size: 1rem;
  display: flex;
  column-gap: 1rem;
  align-items: flex-start;
  font-weight: 600;
  justify-content: space-between;
}

.map-card h1 img {
  width: 12px;
  min-width: 12px;
  cursor: pointer;
}

.map-card h2 {
  font-size: 0.9rem;
  color: #595858;
  font-weight: 500;
}

.map-card ul li {
  font-size: 0.9rem;
  color: #090a0a;
  font-weight: 600;
}

.map-card ul.client-list {
  padding-left: 0;
}

.map-card ul.client-list li {
  font-size: 0.9rem;
  color: #090a0a;
  font-weight: 600;
  display: flex;
  /* align-items: center; */
  column-gap: 5px;
}

.map-card ul.client-list li span:nth-of-type(1) {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.map-card ul.client-list li span:nth-of-type(2) {
  color: #595858;
  padding-left: 16px;
}

.map-card ul.client-list li {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.map-card ul li div {
  width: 10px;
  height: 10px;
  min-width: 10px;
  border-radius: 50%;
}

.grant-div {
  background-color: #007bff;
}

.independent-div {
  background-color: #a2cfff;
}

.map-card a {
  color: #007bff;
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 1rem;
  font-weight: 600;
  justify-content: center;
}

.map-card a svg {
  width: 12px !important;
  min-width: 12px !important;
}

.map-box-wrapper {
  position: relative;
}

.map-box-wrapper::-webkit-scrollbar {
  width: 12px;
  height: 5px;
}

.map-box-wrapper::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 6px;
}

.map-box-wrapper::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.map-box::-webkit-scrollbar {
  width: 12px;
  height: 5px;
}

.map-box::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 6px;
}

.map-box::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

/* @media screen and (max-width:1400px) {


    .map-card {
        position: absolute;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
} */

@media screen and (max-width: 991px) {
  .map-content-left {
    width: 100%;
  }

  .map-container-body {
    flex-direction: column;
    row-gap: 4rem;
  }
}

@media screen and (max-width: 576px) {
  .map-section {
    padding: 3rem 0rem 4rem 0rem;
  }

  .map-content-left {
    width: 100%;
  }

  .impact-card h1 {
    font-size: 2rem;
  }

  .map-container-header h1 span {
    font-size: 0.9rem;
  }

  .map-container-header h1 {
    font-size: 1.7rem;
  }

  .map-content-left .map-content-heading {
    font-size: 1.3rem;
  }
}
