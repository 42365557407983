/* banner */
.services-banner-work-wrapper {
    background: url('../../../assets/images/services-work.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
}

.services-section {
    min-height: 100vh;
    padding: 0rem 4rem 4rem 4rem;
    display: flex;
    align-items: center;

}

.services-container {
    background: url('../../../assets/images/mission-img.JPG');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: calc(100vh - 8rem);
    border-radius: 15px;
    padding: 0;
    position: relative;
}

.services-container-header {
    background: #0F141C;
    height: fit-content;
    width: calc(100% - 25%);
    margin-left: auto;
    padding: 2rem;
    border-bottom-left-radius: 15px;
}

.services-container-header h1 {
    color: #FFD200;
    font-size: 2.5rem;
    margin: 0;
    font-weight: 600;
    display: flex;
    flex-direction: column;
}

.services-container-header h1 span {
    color: white;
}

.services-container-body {
    background: #0F141C;
    height: fit-content;
    width: 50%;
    margin-left: auto;
    padding: 2rem;
    border-bottom-left-radius: 15px;
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
}

.services-container-body p {
    font-size: 1rem;
    margin: 0;
    color: #828384;
}

.services-container-body a {
    text-decoration: none;
    padding: 0.8rem 2rem;
    background-color: #007BFF;
    color: white;
    border-radius: 10px;
    display: flex;
    column-gap: 5px;
    align-items: center;
    width: fit-content;
}

.services-container-footer {
    background: #0F141C;
    height: fit-content;
    width: fit-content;
    padding: 2rem;
    border-bottom-right-radius: 15px;
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
}

.services-container-footer a {
    text-decoration: none;
    padding: 0.8rem 2rem;
    background-color: #007BFF;
    color: white;
    border-radius: 10px;
    display: flex;
    column-gap: 5px;
    align-items: center;
    width: fit-content;
}

@media screen and (max-width:767px) {
    .services-container {
        background-image:
            linear-gradient(to bottom, rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80)),
            url('../../../assets/images/mission-img.JPG');
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .services-container-header,
    .services-container-body {
        width: 100%;
        background-color: transparent;
    }

}

@media screen and (max-width:576px) {
    .services-section {
        padding: 2rem 1rem;
    }

    .services-container-header,
    .services-container-body {
        padding: 1rem;
    }

    .services-container-header h1 {
        font-size: 1.7rem;
    }
}

/* serviceWork-section */
.serviceWork-section {
    padding: 3rem 0;
}

.serviceWork-container {
    position: relative;
}

.serviceWork-container-header {
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.serviceWork-container-header h1 {
    display: flex;
    flex-direction: column;
    font-size: 2.5rem;
    font-weight: 600;
    color: white;
    margin: 0;
    padding-bottom: 2rem;
    width: fit-content;
    margin: 0 auto;
    text-align: center;
    position: relative;
}

.serviceWork-container-header h1 img {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.serviceWork-container-header p {
    color: #828384;
    font-size: 1rem;
    margin: 0;
    max-width: 800px;
    text-align: center;
    margin: 0 auto;
    background: linear-gradient(to right, #131b23c4, #0e2947a2);
    border-radius: 10px;
    padding: 1rem 0;
}

.serviceWork-container-body {
    display: flex;
    justify-content: center;
    padding-top: 4rem;
}

.serviceWork-circle:nth-of-type(1) {
    margin-left: unset;
}

.serviceWork-circle {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.30);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -30px;
    overflow: hidden;
}

.serviceWork-circle h1 {
    color: white;
    margin: 0;
    font-size: 1.5rem;
    max-width: 70%;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
}

.serviceWork-container-footer {
    padding-top: 4rem;
}

.serviceWork-container-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #414E66;
    padding: 1rem 0;
    column-gap: 2rem;
    background: linear-gradient(to right, #131b23c4, #0e2947a2);
    border-radius: 10px;
    padding: 1rem 1rem;
}

.serviceWork-container-list h1 {
    font-size: 1.7rem;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    margin: 0;
    row-gap: 0.5rem;
    min-width: 300px;
}

.serviceWork-container-list h1 span {
    font-size: 1rem;
    color: #007BFF;
}

.serviceWork-container-list p {
    color: white;
    margin: 0;
    font-size: 1.2rem;
    max-width: 700px;
}

@media screen and (max-width:1199px) {
    .serviceWork-circle {
        width: 250px;
        height: 250px;
    }
}

@media screen and (max-width:991px) and (min-width:768px) {
    .serviceWork-circle {
        width: 200px;
        height: 200px;
        margin-left: -15px;
    }

    .serviceWork-circle h1 {
        font-size: 1.2rem;
    }
}

@media screen and (max-width:767px) {
    .serviceWork-container-body {
        flex-direction: column;
        align-items: center;
    }

    .serviceWork-circle {
        margin-left: unset;
        margin-top: -30px;
    }

    .serviceWork-container-list {
        flex-direction: column;
        row-gap: 1rem;
    }

    .serviceWork-container-list h1 {
        min-width: 100%;
    }
}

@media screen and (max-width:576px) {
    .serviceWork-container-header h1 {
        font-size: 1.7rem;
    }

    .serviceWork-container-list h1 {
        font-size: 1.3rem;
    }

    .serviceWork-container-list p {
        font-size: 1rem;
    }
}