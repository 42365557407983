.ck-content {
  min-height: 400px;
}
.submit-btn-wrapper button {
  background-color: #007bff;
  color: white;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
}
