.main-solar-section {
  padding-top: 90px;
  padding-bottom: 90px;
  background-image: url("../../../assets/images/grid-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  color: white;
}

.main-solar-container {
  position: relative;
}

.solar-Main-Heading {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.solar-Main-Heading h1 {
  font-size: 2.5rem;
  color: white;
  display: flex;
  font-weight: 500;
}

.solar-Main-Heading span {
  display: flex;
  flex-direction: column;
  color: #007bff;
  position: relative;
  padding-left: 10px;
}

.solar-Main-Heading span img {
  position: absolute;
  width: 100%;
  bottom: -30px;
}

.solar-tabs-xl {
  margin-top: 90px;
  display: flex;
  width: 100%;
}

.tab-buttons {
  width: 50%;
  padding-right: 20px;
  margin-bottom: 0px !important;
}

.tab-trigger {
  background: #2e3a4f;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 20px;
  padding: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.7s;
}

.tab-trigger:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px white;
}

.tab-trigger:hover {
  transform: scale(1.04);
  box-shadow: 0 0 5px white;
}

.tab-contents {
  margin-top: 80px;
  width: 50%;
  background-image: linear-gradient(
    to right,
    rgb(255, 255, 255),
    rgb(223, 213, 245)
  );
  border-radius: 30px 0px 30px 30px;
  position: relative;
}

.tab-trigger.active {
  background: white;
  color: black;
}

.content-header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: -49px;
  min-height: 50px;
}

.tab-trigger-heading {
  background: #0f141c;
  padding: 10px;
  border-radius: 0px 0px 30px 0px;
  top: -1px;
  left: -1px;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0px;
  width: 60%;
}

.empty-tab-trigger-heading {
  width: 40%;
  background-image: linear-gradient(
    to right,
    rgb(233, 226, 248),
    rgb(223, 213, 245)
  );
  border-radius: 30px 30px 0px 0px;
}

.content-heading {
  white-space: nowrap;
  width: 100%;
}

.solar-Main-Heading {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.solar-Main-Heading h1 {
  font-size: 2.5rem;
  color: white;
  display: flex;
}

.solar-Main-Heading span {
  display: flex;
  flex-direction: column;
  color: #007bff;
  position: relative;
  padding-left: 10px;
}

.solar-Main-Heading span img {
  position: absolute;
  width: 100%;
  bottom: -30px;
}

.solar-tabs-xl {
  margin-top: 90px;
  display: flex;
  width: 100%;
}

.tab-buttons {
  width: 50%;
  padding-right: 20px;
  margin-bottom: 0px !important;
}

.tab-trigger {
  background: #2e3a4f;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 20px;
  padding: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.tab-contents {
  margin-top: 80px;
  width: 50%;
  background-image: linear-gradient(
    to right,
    rgb(255, 255, 255),
    rgb(223, 213, 245)
  );
  border-radius: 30px 0px 30px 30px;
  position: relative;
}

.tab-trigger.active {
  background: white;
  color: black;
}

.content-header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: -49px;
}

.tab-trigger-heading {
  background: #0f141c;
  padding: 10px;
  border-radius: 0px 0px 30px 0px;
  top: -1px;
  left: -1px;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0px;
  width: 60%;
}

.empty-tab-trigger-heading {
  width: 40%;
  background-image: linear-gradient(
    to right,
    rgb(233, 226, 248),
    rgb(223, 213, 245)
  );
  border-radius: 30px 30px 0px 0px;
}

.content-heading {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-body {
  width: 100%;
  height: 100%;
  display: flex;
}

.content-body-text {
  width: 70%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-body-image {
  width: 30%;
  display: flex;
  align-items: center;
}

.content-body-image img {
  width: 80%;
  max-width: 150px;
  height: auto;
  animation: loading 3s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.content-body-text p {
  font-size: 0.8rem;
  color: black;
  font-weight: 500;
  margin-bottom: 0px;
}

.content-body-text p span {
  color: #007bff;
}

.solar-tab-small {
  display: none;
}

@media screen and (max-width: 991px) {
  .solar-Main-Heading h1 {
    font-size: 2rem;
  }

  .solar-tabs-xl {
    display: none;
  }

  .tab-buttons-sm {
    width: 100%;
    padding: 0;
    margin-bottom: 0px !important;
  }

  .tab-trigger-sm {
    padding: 18px;
    font-size: 1.2rem;
  }

  .tab-trigger-sm img {
    width: 26px;
    transform: rotate(-45deg);
  }

  .solar-tab-small {
    margin-top: 60px;
    display: block;
  }

  .tab-trigger-sm {
    background: #2e3a4f;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tab-trigger-sm.active {
    display: none;
  }

  .tab-contents-sm {
    margin-top: 80px;
    width: 100%;
    background-image: linear-gradient(
      to right,
      rgb(255, 255, 255),
      rgb(223, 213, 245)
    );
    border-radius: 15px 0px 15px 15px;
    position: relative;
  }

  .tab-trigger-heading {
    border-radius: 0px 0px 15px 0px;
  }

  .empty-tab-trigger-heading {
    border-radius: 15px 15px 0px 0px;
  }

  .tab-contents-sm.active {
    margin-top: 0px;
  }

  .content-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .content-header {
    top: -26px;
  }

  .content-heading {
    font-size: 1rem;
  }

  .content-body-text {
    width: 100%;
  }

  .content-body-image {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .content-body-image img {
    width: 35%;
    max-width: 150px;
    height: auto;
  }
}

.solar-mobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .solar-Main-Heading h1 {
    flex-direction: column;
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .main-solar-section {
    padding: 2rem 0;
  }

  .solar-desktop {
    display: none;
  }

  .solar-mobile {
    display: flex;
  }

  .solar-Main-Heading h1 {
    font-size: 1.7rem;
  }

  .solar-Main-Heading span img {
    bottom: -20px;
  }

  .tab-trigger-heading {
    width: 70%;
    padding: 7px;
  }

  .tab-trigger-heading p {
    margin: 0;
  }

  .empty-tab-trigger-heading {
    width: 30%;
  }
}
