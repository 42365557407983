.independence-section {
    padding: 3rem 0;
}

.independence-container {
    background-color: #f0f1f5cc;
    border-radius: 15px;
    position: relative;
}

.independence-container-header h1 {
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0;
    background-color: white;
    width: fit-content;
    margin: 0 auto;
    padding: 1rem 1rem;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    text-align: center;

}

.independence-container-body {
    display: flex;
    align-items: center;
    padding: 3rem 0;
    column-gap: 2rem;
}

.independence-container-left {
    width: 50%;
}

.independence-container-left img {
    width: 100%;
}

.independence-container-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}

.independence-container-right h1 {
    font-size: 2rem;
    font-weight: 500;
    color: black;
    margin: 0;
}

.independence-container-right h1 span {
    color: #007BFF;
}

.independence-container-right ul li {
    font-size: 1rem;
    color: black;
    padding-bottom: 1rem;
}

.independence-container-right a {
    text-decoration: none;
    padding: 0.8rem 2rem;
    background-color: #007BFF;
    color: white;
    border-radius: 10px;
    display: flex;
    column-gap: 5px;
    align-items: center;
    width: fit-content;
}

@media screen and (max-width:991px) {
    .independence-container-body {
        flex-direction: column;
        row-gap: 2rem;
    }

    .independence-container-left {
        width: 100%;
        padding: 2rem;
    }

    .independence-container-right {
        width: 100%;
    }
}

@media screen and (max-width:576px) {
    .independence-container-header h1 {
        font-size: 1.7rem;
        width: 100%;
    }

    .independence-container-right h1 {
        font-size: 1.5rem;
    }
}