.slider-wrapper {
  margin-top: 80px;
  height: calc(100vh - 110px);
  padding: 5px 30px 0px 30px;
  background-color: #0f141c;
  position: relative;
}

.slick-vertical .slick-slide {
  border: none !important;
}

.slick-list {
  border-radius: 20px;
}

.slick-active {
  height: 30px !important;
}

@media screen and (min-width: 992px) {
  .slick-dots {
    position: absolute;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    display: flex !important;
    flex-direction: column;
    row-gap: 1rem;
    width: fit-content !important;
    right: 0 !important;
    top: 50%;
    transform: translate(0%, -50%);
    justify-content: center;
  }
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  width: 5px !important;
  height: 30px !important;
  background-color: white !important;
  border-radius: 10px !important;
}

.slick-dots li button:before {
  font-size: 7px !important;
  opacity: 0.8 !important;
  color: transparent !important;
  width: 5px !important;
  height: 5px !important;
  background-color: white !important;
  border-radius: 50%;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.slide-content {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(100vh - 115px);
  opacity: 0;
  transition: opacity 2s ease;
  position: relative;
}

.slide-content video {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  height: 100vh;
  object-fit: cover;
}

.slick-active .slide-content {
  opacity: 1;
}

.slide-content-one {
  background-image: url("/src/assets/images/image1.png");
}

.slide-content-one {
  -webkit-animation: slidein 50s;
  animation: slidein 50s !important;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards !important;

  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite !important;

  -webkit-animation-direction: alternate;
  animation-direction: alternate !important;
}

@keyframes slidein {
  from {
    background-position: center;
    background-size: 2000px;
  }

  to {
    background-position: -100px 0px;
    background-size: 2750px;
  }
}

.slide-content-three {
  background-image: url("/src/assets/images/image2.png") !important;
  -webkit-animation: slidein 50s;
  animation: slidein 50s;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.slide-content-five {
  background-image: url("/src/assets/images/image3.png") !important;
  -webkit-animation: slidein 50s;
  animation: slidein 50s;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.banner-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.banner-content-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}

.banner-content-right {
  width: 50%;
  z-index: 5;
  position: relative;
}

.banner-heading h1 {
  color: white;
  font-size: 3rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  margin: 0;
  letter-spacing: 1px;
}

.banner-heading h1 span:first-of-type {
  color: #ffd200;
}

.banner-btn button {
  background-color: #007bff;
  color: white;
  font-size: 1.2rem;
  padding: 0.7rem 2rem;
  border: none;
  box-shadow: none;
  border-radius: 10px;
}

.banner-content-box {
  background-color: black;
  padding: 1.5rem;
  border-radius: 10px;
  width: 90%;
  margin: 0 auto;
}

.banner-content-box p {
  color: white;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
}

.banner-content-right .banner-btn {
  display: none;
}

@media screen and (max-width: 991px) {
  .banner-content-wrapper {
    flex-direction: column;
    row-gap: 1rem;
  }

  .slider-wrapper {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .slide-content {
    height: calc(100vh - 80px);
  }

  .slick-list {
    border-radius: 0;
  }

  .banner-content-left {
    width: 100%;
  }

  .banner-content-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .banner-content-box {
    width: 100%;
  }

  .banner-content-left {
    row-gap: 1rem;
  }

  .banner-content-left .banner-btn {
    display: none;
  }

  .banner-content-right .banner-btn {
    display: block;
    width: 100%;
    padding-top: 2rem;
  }

  .banner-content-right .banner-btn button {
    width: 100%;
  }

  .slick-dots {
    position: absolute;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    display: flex !important;
    flex-direction: row !important;
    row-gap: 1rem;
    width: -moz-fit-content !important;
    width: fit-content !important;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 0 !important;
    align-items: center !important;
  }

  .slick-dots li.slick-active button:before {
    width: 30px !important;
    height: 5px !important;
  }
}

@media screen and (max-width: 767px) {
  .banner-heading h1 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 576px) {
  .banner-heading h1 {
    font-size: 1.8rem;
  }

  .banner-content-box p {
    font-size: 1rem;
  }

  .banner-content-box {
    padding: 1rem;
  }
}

/* new */
.mouse-slider {
  position: absolute;
  left: 50%;
  bottom: 80px;
  transform: translate(0%, -50%);
  transition: transform 0.5s ease 0.5s;
}

.mouse-slider .mouse-circle-wrapper {
  position: absolute;
  left: 50%;
}

@keyframes rotate1 {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes pulse1 {
  0% {
    transform: scaleX(1);
  }

  to {
    transform: scale3d(1.4, 1.4, 1);
  }
}

@keyframes pulse2 {
  0% {
    transform: scaleX(1);
  }

  to {
    transform: scale3d(1.4, 1.4, 1);
  }
}

@keyframes pulse3 {
  0% {
    transform: scaleX(1);
  }

  to {
    transform: scale3d(1.3, 1.3, 1);
  }
}

@keyframes swing {
  0% {
    transform: translate(-50%, -55%);
  }

  to {
    transform: translate(-50%, -30%);
  }
}

.mouse-slider .mouse-circle-inner {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50%;
  opacity: 0.1;
}

.mouse-slider .mouse-circle-1 {
  width: 74px;
  height: 74px;
  animation: pulse1 1s ease-in-out 1s infinite alternate;
  background-color: #ffffff;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
}

.mouse-slider .mouse-circle-2 {
  width: 56px;
  height: 56px;
  animation: pulse2 1s ease-in-out 1s infinite alternate;
  background-color: #ffffff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
}

.mouse-slider .mouse-circle-3 {
  width: 44px;
  height: 44px;
  animation: pulse3 1s ease-in-out 1s infinite alternate;
  background-color: #c7c7c7;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
}

.mouse-slider .mouse-icon {
  animation: swing 1s ease-in-out 1s infinite alternate;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .mouse-slider {
    display: none;
  }
}
