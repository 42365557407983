.solar-form {
  padding: 4rem 0;
}
.solar-form form {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 2rem;
  border-radius: 15px;
  position: relative;
}
.form-field-solar label {
  color: #555555;
  font-size: 0.9rem;
  padding-left: 5px;
}
.form-field-solar input {
  height: 50px;
  border-radius: 10px;
  border: 1px solid #e4e4e4 !important;
  outline: none !important;
  box-shadow: none !important;
  font-weight: 500;
  color: #2d2d2e !important;
  background: white !important;
}
.form-field-solar input:active,
.form-field-solar input:focus {
  border: 1px solid #e4e4e4 !important;
  color: #2d2d2e !important;
}
.form-field-solar input::placeholder {
  color: #2d2d2e;
}
.form-field-solar select {
  height: 50px;
  border-radius: 10px;
  border: 1px solid #e4e4e4 !important;
  outline: none !important;
  box-shadow: none !important;
}
.form-field-solar textarea {
  height: 100px;
  resize: none;
  border-radius: 10px;
  border: 1px solid #e4e4e4 !important;
  outline: none !important;
  box-shadow: none !important;
  font-weight: 500;
  color: #2d2d2e !important;
  background: white !important;
}
.form-field-solar textarea::placeholder {
  color: #2d2d2e;
}
.form-field-solar button {
  width: fit-content;
  padding: 0 2rem;
  margin: 0 auto;
  height: 50px;
  font-size: 1.2rem;
  font-weight: 500;
  background: #007bff;
  color: white;
  border-radius: 10px;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-field-solar button:hover {
  background-color: #007bffcc;
}
