.add-blog-heading h1 {
  font-size: 1.5rem;
  font-weight: 600;
}
.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff !important;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
input[type="file"] {
  display: none;
}
.image-preview-container {
  margin-top: 20px;
}

.cover-image-view img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
}
.community-icon-viewer {
  width: 100px;
  height: 100px;
}
.community-icon-viewer img {
  max-width: 100%;
  max-height: 100%;
  width: fit-content;
  object-fit: contain;
}
.blog-content-more-inner {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1rem;
  border-radius: 10px;
}
.blog-content-more textarea {
  resize: none;
  min-height: 100px;
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #e4e4e4 !important;
}
.blog-content-more textarea::placeholder {
  color: black;
  font-weight: 400;
}
.more-btn button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
