.mission-section {
    display: flex;
    align-items: center;
    column-gap: 2rem;

}

.mission-section-left {
    width: 50%;
    width: 50%;
    background: #ffffffbf;
    padding: 1rem;
    border-radius: 10px;
}

.mission-section-right {
    width: 50%;
}

.mission-section-left-header h1 {
    font-size: 2rem;
    font-weight: 600;
    color: black;
    display: flex;
    column-gap: 10px;
}

.mission-section-left-header h1 span {
    width: fit-content;
    position: relative;
    color: #007BFF;
}

.mission-section-left-header h1 span img {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
}

.mission-section-left-body p {
    font-size: 1rem;
    color: black;
    font-weight: 500;
}

.mission-section-left-body p span {
    color: #007BFF;
    font-weight: 600;
}

.mission-section-left-body h1 {
    font-size: 1.5rem;
    font-weight: 600;
}

.mission-section-left-body ul {
    list-style: none;
    padding: 0;
}

.mission-section-left-body ul li div {
    width: 20px;
    height: 35px;
    background-color: #007BFF;
    min-width: 40px;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mission-section-left-body ul li {
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: black;
    font-weight: 500;
    font-size: 1rem;
    margin: 0.5rem 0;
}

.mission-section-left-body-inner {
    background-color: #F0F1F5;
    padding: 1rem;
    border-radius: 10px;
}

.mission-section-left-body-inner p {
    margin: 0;
}

.mission-section-right {
    position: relative;
}

.mission-section-right img {
    width: 100%;
    border-radius: 10px;
    height: 480px;
    object-fit: cover;
}

.mission-section .mission-section-right-icon-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 100px;
    border-bottom-left-radius: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.value-section .mission-section-right-icon-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    border-bottom-right-radius: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mission-section-right-icon-wrapper img {
    width: 80px !important;
    height: 80px !important;
}

.value-section-wrapper {
    position: relative;
}

.value-section {
    display: flex;
    column-gap: 2rem;
    align-items: center;

}


.value-section-btn a {
    background-color: #007BFF;
    border-radius: 10px;
    padding: 1rem 1rem;
    color: white;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    column-gap: 0.3rem;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
}

.value-section-btn a:hover svg {
    transform: rotate(45deg);
}

@media screen and (max-width:767px) {
    .value-section-btn a {
        color: #007BFF;
        background-color: #ffffffbf;
    }

    .value-section-btn a svg path {
        fill: #007BFF;
    }

    .mission-section {
        flex-direction: column;
        row-gap: 1.5rem;

    }

    .value-section {
        flex-direction: column;
        row-gap: 1.5rem;

    }

    .mission-section-left {
        order: 2;
    }

    .mission-section-left {
        width: 100%;
    }

    .mission-section-right {
        width: 100%;
    }
}

@media screen and (max-width:576px) {
    .mission-section-right img {
        height: 300px;
    }
}