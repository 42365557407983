.ourmission-container {
    background: url(../../../assets/images/ourmission-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 15px;
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    position: relative;
}

.ourmission-header h1 {
    color: white;
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0;
    text-align: center;
}

.ourmission-body {
    display: flex;
    row-gap: 2rem;
    flex-direction: column;
    align-items: center;
}

.ourmission-body p {
    color: #FFD200;
    margin: 0;
    font-size: 1.3rem;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.ourmission-body p span {
    color: white;
}

.ourmission-body a {
    text-decoration: none;
    padding: 0.8rem 2rem;
    background-color: #007BFF;
    color: white;
    border-radius: 10px;
    display: flex;
    column-gap: 5px;
    align-items: center;
    width: fit-content;
}

.ourmission-footer {
    display: flex;
    column-gap: 1rem;
    justify-content: space-between;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
}

.ourmission-footer h1 {
    color: white;
    font-size: 1.3rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.ourmission-footer h1 img {
    width: 18px;
    min-width: 18px;
}

@media screen and (max-width:991px) {
    .ourmission-footer {
        flex-direction: column;
    }
}

@media screen and (max-width:576px) {
    .ourmission-header h1 {
        font-size: 1.7rem;
    }
}