.benefit-section {
    padding: 5rem 0;
}

.benefit-container {
    display: flex;
    align-items: center;
    column-gap: 2rem;
    position: relative;
    background-color: rgba(255, 255, 255, 0.699);
    border-radius: 10px;
}

.benefit-container-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
}

.benefit-container-right {
    width: 550px;
    min-width: 550px;
    position: relative;
}

.benefit-container-right img {
    width: 100%;
}

.benefit-container-right-content {
    position: absolute;
    width: 100%;
    top: 20px;
    left: 20px;
    display: flex;
    column-gap: 4rem;
}

.bar-card {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;
}

.bar-card h1:nth-of-type(1) {
    white-space: nowrap;
}

.bar-card h1 {
    color: black;
    font-size: 1rem;
    margin: 0;
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    max-width: 80px;
    text-align: center;
    margin: 0 auto;
}

.bar-card img {
    width: 50px;
}

.benefit-key-value {
    position: absolute;
    right: 10px;
    top: 125px;
    width: 150px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.benefit-key-value h1 {
    color: black;
    font-size: 1.5rem;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}

.benefit-container-left h1 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #828384;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}

.benefit-container-left h1 span {
    color: black;
}

.benefit-container-left p {
    font-size: 1rem;
    color: black;
    margin: 0;
}

.benefit-container-left a {
    text-decoration: none;
    padding: 0.8rem 2rem;
    background-color: #007BFF;
    color: white;
    border-radius: 10px;
    display: flex;
    column-gap: 5px;
    align-items: center;
    width: fit-content;
}

@media screen and (max-width:991px) {
    .benefit-container {
        flex-direction: column;
        row-gap: 2rem;
    }

    .benefit-container-left {
        order: 2;
    }
}

@media screen and (max-width:767px) {
    .benefit-container-right {
        width: 530px;
        min-width: 530px;
    }
}

@media screen and (max-width:576px) {
    .benefit-section {
        padding-bottom: 2rem;
    }

    .benefit-container-right img.rectangle-back {
        display: none;
    }

    .benefit-container-right {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        min-width: 100%;
        background-color: #F0F1F5;
        padding: 1rem;
        border-radius: 10px;
    }

    .benefit-container-right-content {
        order: 2;
    }

    .benefit-key-value {
        width: 100%;
    }

    .benefit-key-value {
        position: unset;
    }

    .benefit-container-right-content {
        position: unset;
        justify-content: space-between;
        column-gap: 1rem;
    }

    .bar-card {
        justify-content: space-between;
    }

    .benefit-container-left h1 {
        font-size: 1.7rem;
    }
}