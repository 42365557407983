.auth-section {
  display: flex;
  min-height: 100vh;
  padding: 0;
}

.auth-section-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  background-image: linear-gradient(to right, #141a24, #2e3a4f);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.auth-section-banner img {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2);
  z-index: 1;
}

.auth-section-banner-content p {
  color: white;
  font-size: 1rem;
  font-weight: 400;
}

.auth-section-form {
  display: flex;
  align-items: center;
  width: 50%;
}

.auth-section-form-content {
  width: 80%;
  margin: 0 auto;
}

.auth-section-form-content form {
  padding-top: 1.5rem;
}

.auth-logo {
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  justify-content: center;
  align-items: center;
}

.auth-logo img {
  max-width: 200px;
  width: 100%;
  min-width: 100px;
}

.auth-logo p {
  font-size: 1rem;
  margin: 0;
}

.form-heading h1 {
  color: #007bff;
  font-size: 2rem;
  font-weight: 500;
}

.form-field input {
  height: 50px;
  border-radius: 10px;
  border: 1px solid #e4e4e4 !important;
  outline: none !important;
  box-shadow: none !important;
  font-weight: 500;
  color: #2d2d2e !important;
  background: white !important;
}
.form-field input:active,
.form-field input:focus {
  border: 1px solid #e4e4e4 !important;
  color: #2d2d2e !important;
}
.form-field input::placeholder {
  color: #2d2d2e;
}
.form-field select {
  height: 50px;
  border-radius: 10px;
  border: 1px solid #e4e4e4 !important;
  outline: none !important;
  box-shadow: none !important;
}
.form-field input[type="checkbox"] {
  height: 20px;
  width: 20px;
}

.form-field label {
  color: #555555;
  font-size: 1rem;
}

.form-field button {
  background-color: #007bff;
  color: white;
  width: 100%;
  height: 50px;
  border: 1px solid #007bff;
  box-shadow: none;
  outline: none;
  border-radius: 10px;
}

.form-field button:hover {
  background-color: #007bff;
}

.form-field button.transparent-btn {
  background-color: white;
  color: #007bff;
  box-shadow: none;
  outline: none;
  border-radius: 10px;
}

.form-field button.transparent-btn:hover {
  background-color: #007bff;
  color: white;
}
.form-field-icon {
  position: relative;
}
.form-field-icon input {
  padding-left: 32px;
  color: #2d2d2e !important;
}

.form-field-icon img {
  width: 22px;
}

.form-field-icon img.icon-left {
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.checkbox-wrapper a {
  color: #2d2d2e;
  font-size: 1rem;
}
@media screen and (max-width: 767px) {
  .auth-section-banner {
    display: none;
  }
  .auth-section-form {
    width: 100%;
  }
}
