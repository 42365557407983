.category-wrapper {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}
.category-wrapper .category-list {
  display: flex;
  align-items: center;
  position: relative;
  background: rgb(219, 218, 218);
  width: fit-content;
  border-radius: 5px;
  padding: 0.3rem 30px 0.3rem 10px;
}
.category-wrapper .category-list h1 {
  font-size: 1rem;
  margin: 0;
  padding: 0;
}
.category-wrapper .category-list button {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  right: 5px;
  font-size: 0.7rem;
  background: red;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.add-category {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.add-category button {
  width: 80px;
}
