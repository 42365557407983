.multitab-wrapper {
    padding: 5rem 0;
    background-color: rgba(255, 255, 255, 0.50);
    border-radius: 10px;
    position: relative;
}

.multitab-header h1 {
    display: flex;
    flex-direction: column;
    margin: 0;
    color: black;
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
}

.multitab-header h1 span {
    color: #828384;
}

.multitab-body {
    padding-top: 3rem;
    display: flex;
    align-items: center;
    column-gap: 2rem;
}

.multitab-section-left {
    width: 50%;
}

.multitab-section-right {
    width: 50%;
}

.multitab-section-left-header h1 {
    font-size: 2rem;
    font-weight: 600;
    color: black;
}

.multitab-section-left-header h1 span {
    width: fit-content;
    position: relative;
    color: #007BFF;
}

.multitab-section-left-body p {
    font-size: 1rem;
    color: black;
    font-weight: 500;
}

.multitab-section-left-body a {
    background-color: #007BFF;
    color: white;
    white-space: nowrap;
    text-decoration: none;
    font-size: 1rem;
    height: 40px;
    padding: 0 1.5rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    column-gap: 5px;
}

.multitab-section-left-body p span {
    color: #007BFF;
}

.multitab-section-left-body h1 {
    font-size: 1.5rem;
    font-weight: 600;
}

.multitab-section-left-body ul {
    list-style: none;
    padding: 0;
}

.multitab-section-left-body ul li div {
    width: 20px;
    height: 35px;
    background-color: #007BFF;
    min-width: 40px;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.multitab-section-left-body ul li {
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: black;
    font-weight: 500;
    font-size: 1rem;
    margin: 0.5rem 0;
}

.multitab-section-left-body-inner {
    background-color: #F0F1F5;
    padding: 1rem;
    border-radius: 10px;
}

.multitab-section-left-body-inner p {
    margin: 0;
}

.multitab-section-right {
    position: relative;
    overflow: hidden;
}

.multitab-section-right img {
    width: 100%;
    border-radius: 10px;
    height: 450px;
    object-fit: cover;
}

.multitab-body .multitab-section-right-icon-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 150px;
    height: 150px;
    border-bottom-left-radius: 10px;
    background-color: rgba(255, 255, 255, 0.80);
    display: flex;
    align-items: center;
    justify-content: center;
}


.multitab-section-right-icon-wrapper img {
    width: 100px !important;
    height: 100px !important;
}

/* tabs */
.multitab-tabs {
    display: flex;
    justify-content: space-between;
    width: 100%;
}


.tabs {
    flex: 1;
    position: relative;
}

.tabs::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #F0F1F5;
    top: 50%;
    transform: translateY(-50%);
}

.tab-toggle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid #F0F1F5;
    background-color: white;
    font-size: 0.8rem;
    font-weight: 500;
    color: black;
    position: relative;
    cursor: pointer;

}

.tabs:nth-of-type(1) .tab-toggle {
    margin-left: auto;
}

.tabs:last-child .tab-toggle {
    margin-left: auto;
}

.tabs .tab-toggle {
    margin-left: auto;
}


.tabs.active .tab-toggle {
    border-color: #007BFF;
    background-color: #007BFF;
    color: white;
}

.tabs.active::before {
    background-color: #007BFF;
}

.tabs.old-active .tab-toggle {
    border-color: #007BFF;
    background-color: #007BFF;
    color: white;
}

.tabs.old-active::before {
    background-color: #007BFF;
}

@media screen and (max-width:767px) {

    .multitab-body {
        flex-direction: column;
        row-gap: 1.5rem;

    }

    .multitab-section-left {
        order: 2;
    }

    .multitab-section-left {
        width: 100%;
    }

    .multitab-section-right {
        width: 100%;
    }
}

@media screen and (max-width:576px) {
    .multitab-wrapper {
        padding: 2rem 0;
    }

    .multitab-tabs {
        flex-wrap: wrap;
        column-gap: 0.5rem;
        row-gap: 0.5rem;
        justify-content: center;
    }

    .tabs {
        flex: none;
    }

    .multitab-header h1 {
        font-size: 1.7rem;
    }

    .multitab-section-right img {
        height: 300px;
    }

    .multitab-body .multitab-section-right-icon-wrapper {
        width: 100px;
        height: 100px;
    }


    .multitab-section-right-icon-wrapper img {
        width: 80px !important;
        height: 80px !important;
    }
}

/* multitab-section-one */
.multitab-section-one .multitab-container .multitab-body .multitab-section-left {
    order: 2;
}

.multitab-section-one .multitab-container .multitab-body .multitab-section-right .multitab-section-right-icon-wrapper {
    left: 0;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 10px
}

/* multitab-section-two */
.multitab-section-two .multitab-container .multitab-body .multitab-section-left {
    order: 2;
}

.multitab-section-two .multitab-container .multitab-body .multitab-section-right .multitab-section-right-icon-wrapper {
    left: 0;
    bottom: 0;
    top: unset;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px
}

/* multitab-section-three */


.multitab-section-three .multitab-container .multitab-body .multitab-section-right .multitab-section-right-icon-wrapper {
    right: 0;
    bottom: 0;
    top: unset;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px
}