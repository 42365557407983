.about-banner {
    background-color: #0F141C;
    padding: 2rem 0;
    display: flex;
}

.about-banner-header {
    width: calc(100% - 100px);
    background-color: #0F141C;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    padding: 1rem;
    position: relative;
    z-index: 1;
}

.about-banner-header h1 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #FFD200;
    margin: 0;
    display: flex;
    flex-direction: column;
    width: calc(100% - 250px);
}

.about-banner-header h1 span {
    color: white;
}

.about-banner-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.about-banner-header-content p {
    color: #9B999A;
    font-size: 1rem;
    font-weight: 500;
    width: 60%;
    margin: 0;
}

.about-banner-header-content-right {
    display: flex;
    column-gap: 1rem;
}

.about-banner-header-content-right-images {
    display: flex;
    align-items: center;
}

.about-banner-header-content-right-images img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid white;
}

.about-banner-header-content-right-images img:nth-of-type(2) {
    margin-left: -20px;
}

.about-banner-header-content-right-images img:nth-of-type(3) {
    margin-left: -20px;
}

.about-banner-header-content-right-button {
    position: relative;
    width: fit-content;
}

.about-banner-header-content-right-button img {
    position: absolute;
    top: -110px;
    height: 100px;
    width: 150px;
    left: -100px;
}

.about-banner-header-content-right-button a {
    background-color: #007BFF;
    color: white;
    white-space: nowrap;
    text-decoration: none;
    font-size: 1rem;
    height: 40px;
    padding: 0 1.5rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}



.about-banner-body {
    background: url(../../../assets/images/sunchoice-img.JPG);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    border-radius: 10px;
    height: 500px;
    margin-top: -70px;
    position: relative;
}

.about-banner-body-left {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #0F141C;
    width: 60px;
    height: 200px;
    border-top-right-radius: 10px;
}

.about-banner-body-right {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #0F141C;
    width: 200px;
    height: 100px;
    border-top-left-radius: 10px;
}

@media screen and (max-width:991px) {
    .about-banner-header {
        width: calc(100% - 50px);
    }
}

@media screen and (max-width:767px) {
    .about-banner-header {
        width: calc(100% - 0px);
        border-bottom-right-radius: 0;
    }

    .about-banner-body-left {
        height: 100px;
    }

    .about-banner-body-right {
        height: 50px;
    }

    .about-banner-body {
        height: 300px;
        margin-top: unset;
    }

    .about-banner-header h1 {
        width: 100%;
    }

    .about-banner-header-content {
        flex-direction: column;
        row-gap: 1rem;
    }

    .about-banner-header-content p {
        width: 100%;
    }

    .about-banner-header-content-right-button img {
        display: none;
    }
}

@media screen and (max-width:576px) {
    .about-banner-header h1 {
        font-size: 1.7rem;
    }

    .about-banner-header-content p {
        font-size: 0.9rem;
    }

    .about-banner-body {
        height: 250px;
    }

    .about-banner-body-left {
        height: 50px;
    }

    .about-banner-body-right {
        height: 25px;
    }

    .about-banner-body-right {
        width: 100px;
    }
}