.support-section {
    background-color: #0F141C;
}

.support-section .services-container .services-container-header {
    margin-left: unset;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 15px;
}

.support-section .services-container .services-container-body {
    margin-left: unset;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 15px;
}