.blog-container {
    padding: 5rem 0rem;
    background-image: url('../../../assets/images/grid-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

.blog-container-inner {
    position: relative;
}

.blod-section-header {
    padding-bottom: 3rem;
}

.blod-section-header h1 {
    color: white;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0;
    row-gap: 1rem;
    background-color: #0f141ca1;
    padding: 1rem 0rem;
    border-radius: 10px;
    position: relative;
    width: fit-content;
    margin: 0 auto;
}

.blod-section-header h1 img {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -20px;
}

.blod-section-header h1 span {
    font-size: 1rem;
    font-weight: 500;
    color: #FEFEFE;
}

.blog-card-wrapper {
    padding: 2rem 1rem;
    height: 100%;
}

.blog-card-content {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.blog-card {
    background-color: #2E3A4F;
    border-radius: 10px;
    min-height: 500px;
    padding: 0.5rem 0.5rem 1.5rem 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 1rem;
    height: 100%;
    transition: all 1s;
}

.blog-card:hover {
    transform: scale(1.1);
}

.blog-card-header img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    object-position: center;
}

.blog-card-body {
    padding: 0rem 1rem;
}

.card-tag-wrapper {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    flex-wrap: wrap;
    padding-bottom: 1rem;
}

.card-tag-wrapper .tag-box.active {
    background-color: #495976;
    border-radius: 5px;
    padding: 0.3rem 0.5rem;
}

.card-tag-wrapper .tag-box h1 {
    color: white;
    font-size: 1rem;
    margin: 0;
    font-weight: 400;
}

.blog-card-body-content {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.blog-card-body-content h1 {
    color: white;
    font-weight: 600;
    font-size: 1.5rem;
    margin: 0;
}

.blog-card-body-content p {
    color: white;
    font-weight: 500;
    font-size: 1rem;
    margin: 0;
}

.blog-card-footer {
    padding: 0rem 1rem;
}

.blog-card-footer a {
    background-color: #007BFF;
    height: 45px;
    border-radius: 10px;
    color: white;
    padding: 0 1.2rem;
    font-size: 1rem;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    column-gap: 0.4rem;
}

.blog-card-footer a:hover {
    background-color: white;
    color: #007BFF;
}

.blog-card-footer a:hover svg {
    transform: rotate(45deg);
}

.blog-card-footer a:hover svg path {
    fill: #007BFF;
}

.blod-section-footer {
    padding-top: 2.5rem;
}

.blod-section-footer a {
    color: #007BFF;
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    column-gap: 0.3rem;
    justify-content: center;
    text-align: center;
}

.blod-section-footer a svg {
    min-width: 12px;
}

@media screen and (max-width:576px) {
    .blog-card:hover {
        transform: scale(1);
    }

    .blog-container {
        padding: 3rem 0;
    }



    .blod-section-footer a {
        display: block;
    }

    .blog-card-wrapper {
        padding: 0.5rem;
    }

    .blod-section-header h1 {
        font-size: 1.7rem;
    }

    .blog-card {
        min-height: 100%;
    }

    .blog-card-header img {
        height: 180px;
    }

    .blog-card-body-content h1 {
        font-size: 1.2rem;
    }

    .blog-card-body-content {
        row-gap: 0.5rem;
    }
}

.blog-section-whtie {
    background: rgba(255, 255, 255, 0.70);
    position: relative;
}

.blog-section-whtie .blog-container-inner {
    position: unset;
}

.blog-section-whtie .blog-container-inner .blod-section-header h1 {
    color: black;
    background: transparent;
}

.blog-section-whtie .blog-container-inner .blod-section-header h1 span {
    color: black;
}

.blod-section-header h1 span {
    font-size: 1rem;
    font-weight: 500;
    color: #FEFEFE;
}

.blog-section-whtie .blog-container-inner .blog-card-wrapper .blog-card {
    background: #F0F1F5;
}

.blog-section-whtie .blog-container-inner .blog-card-wrapper .blog-card .blog-card-body .card-tag-wrapper .tag-box.active {
    background: white;
}

.blog-section-whtie .blog-container-inner .blog-card-wrapper .blog-card .blog-card-body .card-tag-wrapper .tag-box h1 {
    color: black;
}

.blog-section-whtie .blog-container-inner .blog-card-wrapper .blog-card .blog-card-body .blog-card-body-content h1 {
    color: black;
}

.blog-section-whtie .blog-container-inner .blog-card-wrapper .blog-card .blog-card-body .blog-card-body-content p {
    color: black;
}

.slick-slide {
    height: auto !important;
}

.slick-track {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    align-items: stretch !important;
}

.slick-slide>div {
    height: 100% !important;
}