.consulting-section .about-banner-body {
    background: url('../../../assets/images/slide2.JPG');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: unset;
    position: relative;
}

.consulting-section {
    position: relative;
}

.consulting-section .about-banner-container .about-banner-header {
    background: transparent;
}

.consulting-section .about-banner-body::before {
    content: "";
    background-image: url(../../../assets/images/sun-star-icon.png);
    background-size: cover;
    width: 100px;
    height: 100px;
    position: absolute;
    top: -100px;
    right: 0;

}

.consulting-section .about-banner-body .about-banner-body-left {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #0F141C;
    width: 200px;
    height: 100px;
    border-top-left-radius: 0px;
}

.consulting-section .about-banner-body .about-banner-body-right {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #0F141C;
    width: 60px;
    height: 200px;
    border-top-right-radius: 0px;
}

@media screen and (max-width:767px) {
    .consulting-section .about-banner-body .about-banner-body-left {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #0F141C;
        width: 200px;
        height: 50px;
        border-top-left-radius: 0px;
    }

    .consulting-section .about-banner-body::before {
        display: none;

    }

    .consulting-section .about-banner-body .about-banner-body-right {
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: #0F141C;
        width: 60px;
        height: 100px;
        border-top-right-radius: 0px;
    }
}

@media screen and (max-width:576px) {
    .consulting-section .about-banner-body .about-banner-body-left {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #0F141C;
        width: 100px;
        height: 25px;
        border-top-left-radius: 0px;
    }

    .consulting-section .about-banner-body .about-banner-body-right {
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: #0F141C;
        width: 60px;
        height: 50px;
        border-top-right-radius: 0px;
    }
}