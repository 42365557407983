.question-section {
  padding: 5rem 0;
  background-color: rgba(255, 255, 255, 0.5);
  position: relative;
  border-radius: 10px;
}

.common-heading-black {
  background: transparent;
}

.common-heading-black h1 {
  color: black;
}

.question-container-body {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.question-tab {
  display: flex;
  column-gap: 2rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cecece;
  border-radius: 10px;
  padding: 1rem 1rem;
}

.question-tab h1 {
  color: black;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
}

.question-tab img {
  width: 22px;
  min-width: 22px;
  cursor: pointer;
}

.question-tab-content {
  border-bottom: 1px solid #cecece;
  border-radius: 10px;
}

.question-tab-content-header {
  display: flex;
  column-gap: 2rem;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}

.question-tab-content-header p {
  color: black;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}

.question-tab-content-header img {
  width: 22px;
  min-width: 22px;
  cursor: pointer;
}

.question-tab-content-body {
  padding: 1rem 1rem;
}

.question-tab-content-body p {
  font-size: 1rem;
  color: black;
  margin: 0;
}

@media screen and (max-width: 576px) {
  .question-section {
    padding: 2rem 0;
  }

  .question-tab h1 {
    font-size: 1rem;
  }

  .question-tab-content-header h1 {
    font-size: 1rem;
  }
}
