.blogs-section {
    padding: 5rem 0;
}

.blogs-container {
    position: relative;
    background-color: rgba(255, 255, 255, 0.80);
    border-radius: 10px;
    padding: 1rem 0.5;
}

.pagination-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.pagination-header h1 {
    font-size: 1.5rem;
    color: black;
    margin: 0;
    white-space: nowrap;
    font-weight: 600;
}

.pagination-header .dropdown-pagination {
    display: flex;
    align-items: center;
}

.pagination-header .dropdown-pagination h2 {
    white-space: nowrap;
    font-size: 1rem;
    margin: 0;
    color: #828384;
}

.pagination-header .dropdown-pagination select {
    border: none;
    outline: none;
    box-shadow: none;
    font-weight: 600;
    font-size: 1rem;
    color: black;
}

.pagination-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

}

.pagination-value p {
    color: #828384;
    font-size: 1rem;
    margin: 0;
    font-weight: 500;
    white-space: nowrap;
}

ul.pagination {
    margin: 0;
}

ul.pagination li button {
    background: transparent !important;
    color: #828384;
}

ul.pagination li.active button {
    color: black;
}

.pagination-counter {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
}

.pagination-dots {
    display: flex;
    align-items: flex-end;
    padding-bottom: 4px;
    color: #828384;
}

.pagination-counter button {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    column-gap: .3rem;
    font-size: 1rem;
    font-weight: 600;
    box-shadow: none !important;
    outline: none;
}

.pagination-counter button:hover {
    color: black;
}

.pagination-counter button:disabled {
    color: #828384;
}

.pagination-counter button:disabled svg path {
    stroke: #828384;
}

.pagination-counter button svg {
    width: 8px;
}

.pagination-counter button svg path {
    stroke: #090A0A;
}

@media screen and (max-width:576px) {
    .pagination-footer {
        flex-direction: column;
    }
}