.blog-detail-content-section {
    padding: 5rem 0;
    background-color: rgba(255, 255, 255, 0.80);
    position: relative;
}

.blog-content-wrapper {
    max-width: 850px;
    margin: 0 auto;
}

.blog-content-wrapper h1 {
    color: black;
    font-size: 1.5rem;
    font-weight: 600;
    padding-bottom: 1rem;
}

.blog-content-wrapper p {
    color: black;
    font-size: 1rem;
    text-align: justify;
}

.blog-content-image {
    width: 100%;
    height: 500px;
}

.blog-content-image img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.blog-content-image-two {
    display: flex;
    gap: 2rem;
}

.blog-content-image-two img {
    width: 50%;
}

@media screen and (max-width:767px) {
    .blog-content-image {
        height: 300px;
    }

    .blog-content-image-two {
        display: flex;
        gap: 2rem;
        flex-direction: column;
        height: 100%;
    }

    .blog-content-image-two img {
        width: 100%;
        height: 300px;
    }
}

@media screen and (max-width:576px) {
    .blog-detail-content-section {
        padding: 2rem 0;
    }
}