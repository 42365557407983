.work-banner-section {
    background: url(../../../assets/images/mission-img.JPG);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    position: relative;
}

.work-banner-container .services-container-header {
    width: calc(100% -30%);
    margin-left: unset;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 0px;
    padding-left: 5rem;
}

.work-banner-container .services-container-body {
    background: #0F141C;
    height: fit-content;
    width: 50%;
    margin-left: unset;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 0px;
    padding-left: 5rem;
}

@media screen and (max-width:767px) {
    .work-banner-section {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80)),
            url(../../../assets/images/mission-img.JPG);
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .work-banner-container .services-container-header {
        background: transparent;
        padding: 2rem;
        width: 100%;
    }

    .work-banner-container .services-container-body {
        background: transparent;
        padding: 2rem;
        width: 100%;
    }

}