.service-main-section {
    padding-top: 100px;
    /* position: relative;
    z-index: 5; */
}

.service-heading-header {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.service-heading-content h1 {
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 2.5rem;
    color: black;
}

.service-heading-content h2 {
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 2.5rem;
    color: #828383;
}

.service-heading-header img {
    width: 25%;
    height: auto;
    position: absolute;
    top: -100px;
    right: 0px;
    z-index: -1;
}

.service-card-main {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 2.5rem;
    row-gap: 2rem;
    justify-content: space-between;

}

.service-card-section {
    width: 100%;
}

.service-card.active {
    border-right: 1px solid #007BFF;
    border-bottom: 1px solid #007BFF;
}

.service-card {
    width: 30.33%;
    border-right: 1px solid #CECECE;
    border-bottom: 1px solid #CECECE;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    height: fit-content;
    background-color: #ffffffbf;
}

.service-card:hover {
    transition: all 0.5s;
    transform: scale(1.1);
}



.service-card-body {
    display: flex;
    padding: 20px;
}

.card-body-title {
    width: 70%;
}

.card-body-title p {
    padding-top: 5px;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0px;
}

.card-body-image {
    width: 30%;
    display: flex;
    align-items: end;
    justify-content: end;

}

.card-border.active {
    position: absolute;
    background: #007BFF;
    width: 60%;
    height: 5px;
    border-radius: 5px;
    bottom: 0px;
    left: 0px;
}

.service-tab-content {
    padding-top: 20px;
    margin-top: 20px;
}

.service-company-tab {
    display: flex;
    align-items: center;
}

.service-company-tab-left {
    width: 450px;
    min-width: 450px;
    position: relative;
}

.service-company-tab-left img {
    width: 450px;
    min-width: 450px;
}

.service-company-tab-left-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 450px;
}

.service-company-tab-left-header {
    position: absolute;
    top: 130px;
    left: 90px;
    height: 100px;
    display: flex;
    align-items: flex-end;
    padding-right: 35px;
}

.service-company-tab-left-header h1 {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    color: black;
}

.service-company-tab-left-body {
    position: absolute;
    top: 250px;
    left: 30px;
    height: 120px;
    padding-right: 35px;
}

.service-company-tab-left-body p {
    font-size: 0.9rem;
    color: black;
    margin: 0;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 6;
}

.service-company-tab-right {
    width: 100%;
    margin-left: -35px;
    position: relative;
}

.service-company-tab-right img {
    width: 100%;
    border-radius: 10px;
    max-height: 410px;
    object-fit: cover;
}

.service-card-main-sm {
    display: none;
}

@media screen and (max-width:991px) {

    .service-heading-header img {
        width: 30%;
    }

    .service-card {
        display: none;
    }

    .service-content-main {
        display: none;
    }

    .service-card-main-sm {
        display: block !important;
    }

    .service-card-sm {
        width: 100%;
        border-right: 1px solid #CECECE;
        border-bottom: 1px solid #CECECE;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        height: fit-content;
        margin-top: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        background-color: #ffffffbf;
    }

    .service-card-sm.active {
        border-right: 1px solid #007BFF;
        border-bottom: 1px solid #007BFF;
    }

    .service-company-tab {
        flex-direction: column;
    }

    .service-company-tab-left-content {
        width: 100%;
    }

    .service-company-tab-left-content,
    .service-company-tab-left-body {
        position: unset;
        height: unset;
    }

    .service-company-tab-left img {
        display: none;
    }

    .service-company-tab-left-header {
        display: none;
    }

    .service-company-tab-right {
        margin-left: unset;
        z-index: 2;
    }

    .service-company-tab-left {
        order: 2;
        margin-top: -10px;
        width: 100%;
        min-width: 100%;

    }

    .service-company-tab-left-body {
        background-color: #F0F1F5;
        padding: 1rem;
        border-radius: 10px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .service-company-tab-right img {
        height: 300px;
        object-position: bottom;
    }

    .service-company-tab-left-mobile {
        height: 40px;
        background-color: #F0F1F5;
        width: 100%;
        position: relative;
    }

    .service-company-tab-left-mobile-two {
        height: 30px;
        background-color: #F0F1F5;
        width: 100%;
        position: relative;
    }

    .service-company-tab-left-mobile::before {
        content: '';
        width: 40%;
        height: 40px;
        background-color: white;
        border-bottom-right-radius: 10px;
        left: 0;
        top: 0;
        position: absolute;
    }

    .service-company-tab-left-mobile-two::before {
        content: '';
        width: 30%;
        height: 30px;
        background-color: white;
        border-top-right-radius: 10px;
        left: 0;
        top: 0;
        position: absolute;
    }

    .service-tab-content {
        margin-top: 0px;
        padding-top: 20px;
    }

    .service-company-tab-left-body p {
        display: unset;
        -webkit-box-orient: unset;
        overflow: unset;
        -webkit-line-clamp: unset;
    }
}

@media screen and (max-width:576px) {
    .service-company-tab-right img {
        height: 190px;
    }

    .card-border.active {
        width: 70%;
    }

    .service-heading-content h2 {
        width: 100%;
        font-size: 1.7rem;
    }

    .service-heading-content h1 {
        font-size: 1.7rem;
    }


    .service-main-section {
        padding-top: 50px;
    }

    .service-heading-header img {
        top: -50px;
        right: -20px;
    }

    .service-card-sm {
        margin-top: 25px;
        padding: 1.5rem 0.8rem;
    }

    .service-card-body {
        padding: 0px;
    }

    .card-body-title img {
        width: 40px;
    }

    .card-body-title p {
        font-size: 1.2rem;
    }

    .card-body-image img {
        width: 25px;
        min-width: 25px;
    }
}