.contact-section {
  padding: 5rem 0rem;
  background: #0f141c;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 5rem;
  position: relative;
}

.contact-container {
  display: flex;
  column-gap: 3rem;
  row-gap: 2rem;
  align-items: center;
}

.contact-container-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.contact-container-left h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: white;
  margin: 0;
  line-height: 3.5rem;
}

.contact-container-left h1 span {
  color: #ffd200;
}

.contact-container-left p {
  color: #828384;
  font-size: 1rem;
  margin: 0;
}

.contact-container-right {
  width: 50%;
}

.form-field-contact label {
  color: white;
  font-size: 1rem;
  padding-bottom: 0.5rem;
}

.form-field input,
.form-field input:focus {
  background: linear-gradient(to right, #141a24, #2e3a4f);
  border-top: none !important;
  border-left: none !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: none !important;
  outline: none !important;
  height: 50px;
  color: white !important;
  font-size: 1rem;
}
.form-field-contact input,
.form-field-contact:focus {
  background: linear-gradient(to right, #141a24, #2e3a4f) !important;
  border-top: none !important;
  border-left: none !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: none !important;
  outline: none !important;
  height: 50px;
  color: white !important;
  font-size: 1rem;
}
.form-field-contact textarea {
  resize: none;
  background: linear-gradient(to right, #141a24, #2e3a4f);
  border: none;
  border-top: none !important;
  border-left: none !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: none !important;
  outline: none !important;
  height: 100px;
  color: white !important;
  font-size: 1rem;
  padding: 0.5rem;
}

.form-field-contact input::placeholder,
.form-field-contact textarea::placeholder {
  color: #828384;
}

.form-field button {
  width: 100%;
  height: 50px;
  background: #007bff;
  color: white;
  border-radius: 10px;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.form-field-social {
  position: relative;
}
.form-field-social input {
  padding-left: 40px;
}
.form-field-social .icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 22px;
}
.form-field button:hover {
  background-color: #007bffcc;
}

@media screen and (max-width: 767px) {
  .contact-container {
    flex-direction: column;
  }

  .contact-container-left {
    width: 100%;
  }

  .contact-container-right {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .contact-container-left h1 {
    font-size: 1.7rem;
    line-height: normal;
  }

  .contact-section {
    padding: 2rem 0;
  }
}

/* findus-container */
.findus-container .common-heading-border-large h1 {
  padding-bottom: 1.5rem;
}

.contact-card {
  border-radius: 10px;
  padding: 2rem 1.5rem;
}

.contact-card .card-common-header {
  background: transparent;
  padding: 0;
}

.contact-card .card-common-header h1 {
  color: white;
  font-size: 1rem;
  margin: 0;
  text-transform: uppercase;
  column-gap: 1rem;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.contact-card .card-common-header h1 img {
  width: 20px;
  min-width: 20px;
}

.contact-card .card-common-body {
  padding: 0;
}

.contact-card .card-common-body ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: none;
  row-gap: 0;
}

.contact-card .card-common-body ul li {
  font-size: 1rem;
  color: #828384;
}

.error-message {
  color: #ffd200;
  font-size: 0.9rem;
}
