.pioneer-section {
    background: url(../../../assets/images/grid-bg.png);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 6rem 0;
}

.pioneer-container {
    position: relative;
    background-color: #0f141c9f;
    border-radius: 15px;
    padding: 1rem 0;
}


.pioneer-container-header h1 {
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 1rem;
}

.pioneer-container-header h1 span {
    color: #FEFEFE99;
    font-size: 1rem;
    font-weight: 500;
    max-width: 800px;
}

.pioneer-container-body {
    display: flex;
    align-items: center;
    column-gap: 2rem;
}

.pioneer-container-body-left {
    display: flex;
    width: 550px;
    min-width: 550px;
}

.pioneer-container-body-left .pioneer-container-body-rectangle-wrapper img.rectangle-back {
    width: 300px;
    min-width: 300px;
}

.pioneer-container-body-left .pioneer-container-body-rectangle-wrapper {
    position: relative;
}

.pioneer-container-body-rectangle-wrapper h1 {
    font-size: 1.2rem;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    position: absolute;
    left: 15px;
    top: 240px;
    width: 160px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
}

.pioneer-container-body-rectangle-wrapper h1 span {
    font-weight: 500;
}

.pioneer-container-body-left img.profile-img {
    width: 350px;
    height: 430px;
    object-fit: cover;
    border-radius: 10px;
    margin-left: -100px;
    position: relative;
}

.pioneer-container-body-right {
    width: 100%;
}

.pioneer-container-body-right-header h1 {
    font-size: 1.5rem;
    font-weight: 500;
    color: white;
    margin: 0;
}

.pioneer-container-body-right-body {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 2rem;
}

.pionner-value-card {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #9B999A;
    width: calc(50% - 1rem);
    padding: 1rem 0.5rem;
    row-gap: 1rem;
}

.pionner-value-card h1 {
    color: white;
    font-size: 2.5rem;
    font-weight: 600;
    display: flex;
    column-gap: 10px;
    align-items: center;
    margin: 0;
}

.pionner-value-card h1 img {
    width: 25px;
    height: 25px;
}

.pionner-value-card span {
    color: #9B999A;
    font-size: 1rem;
    margin: 0;
}

@media screen and (max-width:1199px) {
    .pioneer-container-body {
        flex-direction: column;
        row-gap: 2rem;
    }

    .pioneer-container-body-left {
        width: 100%;
        min-width: 100%;
        justify-content: center;
    }

}

@media screen and (max-width:576px) {
    .pioneer-section {
        padding: 2rem 0;
    }

    .pioneer-container-body-left {
        flex-direction: column;
    }

    .pioneer-container-body-left .pioneer-container-body-rectangle-wrapper {
        order: 2;
        width: 100%;
    }

    .pioneer-container-body-left {
        width: 100%;
    }

    .pioneer-container-body-left img.profile-img {
        margin-left: unset;
        width: 100%;
        margin: 0 auto;
        height: 300px;
        object-position: top;

    }

    .pioneer-container-body-left .pioneer-container-body-rectangle-wrapper img.rectangle-back {
        display: none;
    }

    .pioneer-container-body-rectangle-wrapper {
        background-color: #2E3A4F;
        padding: 40px 1rem;
        border-radius: 10px;

    }

    .pioneer-container-body-rectangle-wrapper::before {
        content: '';
        width: 50%;
        height: 30px;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #0F141C;
        border-top-left-radius: 10px;
    }

    .pioneer-container-body-rectangle-wrapper::after {
        content: '';
        width: 50%;
        height: 30px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #0F141C;
        border-bottom-right-radius: 10px;
    }

    .pioneer-container-body-rectangle-wrapper h1 {
        position: unset;
    }

    .pioneer-container-body-right-header h1 {
        font-size: 1.2rem;
    }

    .pioneer-container-header h1 {
        font-size: 1.7rem;
    }

    .pioneer-container-body-right-body {
        flex-direction: column;
    }

    .pionner-value-card {
        width: 100%;
    }
}