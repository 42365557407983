.office-section {
    padding: 5rem 0;
    background: #f6f7f98f;
    position: relative;
}

.office-container-header h1 {
    color: black;
    display: flex;
    flex-direction: column;
    margin: 0;
    text-align: center;
    position: relative;
    row-gap: 1rem;
    padding-bottom: 3rem;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    font-size: 2.5rem;
    font-weight: 600;
}

.office-container-header h1 span {
    font-size: 1rem;
    font-weight: 500;
}

.office-container-header h1 img {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.blogCardWhite {
    background: #F0F1F5 !important;
}

.blogCardWhite .blog-card-body .card-tag-wrapper .tag-box.active {
    background: white;
}

.blogCardWhite .blog-card-body .card-tag-wrapper .tag-box h1 {
    color: black;
}

.blogCardWhite .blog-card-body .blog-card-body-content h1 {
    color: black;
}

.blogCardWhite .blog-card-body .blog-card-body-content p {
    color: black;
}

@media screen and (max-width:576px) {
    .office-section {
        padding: 2rem 0;
    }

    .office-container-header h1 {
        font-size: 1.7rem;
    }
}