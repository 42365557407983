.admin-wrapper {
  padding-top: 100px;
  padding-left: 260px;
}
.add-location-section {
  padding: 1rem;
}
.add-location-section form {
  width: 50%;
  margin: 0 auto;
  background: white;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1rem;
}
@media screen and (max-width: 991px) {
  .admin-wrapper {
    padding-left: 0;
  }
  .add-location-section form {
    width: 90%;
  }
}
