.admin-wrapper {
  padding-top: 100px;
  padding-left: 260px;
}
.location-headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.location-headers h1 {
  color: black;
  font-size: 2rem;
  font-weight: 500;
  margin: 0;
}
.location-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.location-right button {
  background-color: #007bff;
  color: white;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
}
.location-headers a {
  background-color: #007bff;
  color: white;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.location-tab-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.location-tab-left {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.location-tab-right input {
  border: 1px solid #eee;
  border-radius: 10px;
  height: 40px;
  padding: 0 1rem;
  min-width: 200px;
}
.location-tab-wrapper button {
  background-color: gray;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 10px;
}
.location-tab-wrapper button.active {
  background-color: #007bff;
  color: white;
}
.table-cell-action {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
.table-cell-action img {
  width: 24px;
  min-width: 24px;
}
@media screen and (max-width: 576px) {
  .location-headers {
    flex-direction: column;
  }
  .location-right {
    flex-direction: column;
    width: 100%;
  }
  .location-right button,
  .location-right a {
    width: 100%;
    text-align: center;
  }
  .location-headers h1 {
    font-size: 1.5rem;
  }
  .location-tab-left {
    flex-direction: column;
    width: 100%;
  }
  .location-tab-left button {
    width: 100%;
  }
  .location-tab-right {
    width: 100%;
  }
  .location-tab-right input {
    width: 100%;
  }
}
/* file-c */
.file-upload-wrapper {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
}
.custom-file-input {
  position: relative;
  display: inline-block;
  flex: 1;
}

.custom-file-input .input-file {
  position: absolute;
  left: -9999px;
}

.custom-file-input label {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.custom-file-input label:hover {
  background-color: #007bff;
}
.file-control-wrapper {
  flex: 1;
}
.file-control-wrapper h2 {
  color: black;
  font-size: 1.2rem;
  margin: 0;
  display: none;
}
.select-type-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
.select-type-wrapper select {
  width: 100%;
  height: 40px;
  border-radius: 10px 0 0 10px;
  border: 1px solid #e4e3e3 !important;
  box-shadow: none !important;
  outline: none !important;
  padding: 0 1rem;
}
.select-type-wrapper button {
  background: #007bff;
  border: none;
  height: 40px;
  color: white;
  border-radius: 0px 10px 10px 0px;
  padding: 0 1rem;
}
@media screen and (max-width: 576px) {
  .file-upload-wrapper {
    flex-direction: column;
  }
  .custom-file-input label {
    width: 100%;
    text-align: center;
  }
}
