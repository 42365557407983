.mission-value-section {
  padding: 3rem 0 5rem 0;
  background-image: url('../../../assets/images/grid-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  color: white;

}

.mission-value-container-header {
  padding-top: 20px;
}

.mission-value-container-header h1 {
  font-size: 2.5rem;
  color: white;
  display: flex;
  position: relative;
  width: fit-content;
  padding-bottom: 20px;
}

.mission-value-container-header h1 img {
  position: absolute;
  width: 100%;
  top: 100%;
}

.mission-value-card {
  background-image: linear-gradient(to right, #141A24, #2E3A4F);
  padding: 30px;
  border-radius: 20px;
  height: 100%;
  transition: all 0.5s;
}

.mission-value-card:hover {
  transform: scale(1.1);
}

.mission-value-card-header img {
  width: 60px;
}

.mission-value-card-body h1 {
  color: white;
  font-size: 1.6rem;
  margin-bottom: 15px;
}

.mission-value-card-body p {
  color: #9B999A;
  font-size: 1rem;
  margin-bottom: 0px;
  font-weight: 600;
}

@media screen and (max-width:576px) {
  .mission-value-container-header h1 {
    font-size: 1.7rem;
  }

  .mission-value-section {
    padding: 1rem 0;
  }
}