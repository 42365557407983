.plan-section {
    background: url('../../../assets/images/grid-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 5rem 0;
}

.plan-container {
    position: relative;
}

.common-heading-border-large h1 {
    font-size: 2.5rem;
    font-weight: 600;
    color: white;
    margin: 0;
    position: relative;
    padding-bottom: 3rem;
    text-align: center;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.common-heading-border-large h1 span {
    color: #007BFF;
}

.common-heading-border-large h1 img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.plan-container-body {
    padding-top: 3rem;
}

@media screen and (max-width:576px) {
    .common-heading-border-large h1 {
        font-size: 1.7rem;
    }

}