.admin-navbar-section {
  padding: 0;
}
.admin-navbar-top {
  background-color: #0f141c;
  padding: 0;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: fixed;
  width: 100%;
  z-index: 50;
}
.admin-navbar-logo-wrapper {
  width: 250px;
  min-width: 250px;
}
.admin-navbar-logo-wrapper img {
  width: 80%;
}
.admin-navbar-right button {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
}
.admin-sidebar {
  width: 260px;
  position: fixed;
  height: calc(100vh - 80px);
  top: 80px;
  background: #0f141c;
  left: 0;
  padding: 20px 20px;
  z-index: 50;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.admin-sidebar ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.admin-sidebar ul li a {
  color: white;
  display: flex;
  border-radius: 10px;
  padding: 1rem 0.5rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.admin-sidebar ul li a img {
  width: 12px;
}
.admin-sidebar ul li button {
  color: white;
  display: flex;
  border-radius: 10px;
  padding: 1rem 0.5rem;
  font-size: 1rem;
  background: transparent;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.admin-sidebar ul li a.nav-active {
  background-color: #007bff;
}
.admin-sidebar ul li a:hover,
.admin-sidebar ul li button:hover {
  background-color: #007bff;
}
.admin-navbar-right {
  display: none;
}
@media screen and (max-width: 991px) {
  .admin-wrapper {
    padding-left: 0;
  }
  .admin-navbar-right {
    display: block;
  }
  .admin-sidebar {
    left: -260px;
    transition: all 1s;
  }
  .open {
    left: 0;
  }
}
.arrow-rotated {
  transform: rotate(180deg);
  transition: all 0.3s;
}
